@font-face {
  font-family: 'icon';
  src:  url('../fonts/icon.eot?2c5i6d');
  src:  url('../fonts/icon.eot?2c5i6d#iefix') format('embedded-opentype'),
    url('../fonts/icon.ttf?2c5i6d') format('truetype'),
    url('../fonts/icon.woff?2c5i6d') format('woff'),
    url('../fonts/icon.svg?2c5i6d#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  /* font-display: block; */
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
/* font-family: 'icon' !important; */
display: inline-block;
font-family: 'icon';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete:before {
  content: "\e901";
}
.icon-audit_tables:before {
  content: "\e900";
}
.icon-copy:before {
  content: "\e902";
}
.icon-dashboard:before {
  content: "\e904";
}
.icon-doc:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-error:before {
  content: "\e90b";
}
.icon-eye:before {
  content: "\e90c";
}
.icon-eye-stroke:before {
  content: "\e90d";
}
.icon-failed:before {
  content: "\e90e";
}
.icon-filters:before {
  content: "\e90f";
}
.icon-key:before {
  content: "\e910";
}
.icon-lock:before {
  content: "\e911";
}
.icon-logout:before {
  content: "\e912";
}
.icon-logs:before {
  content: "\e913";
}
.icon-reports:before {
  content: "\e914";
}
.icon-search:before {
  content: "\e915";
}
.icon-security:before {
  content: "\e916";
}
.icon-shops:before {
  content: "\e917";
}
.icon-sidebar:before {
  content: "\e918";
}
.icon-sidebar_s:before {
  content: "\e919";
}
.icon-success:before {
  content: "\e91a";
}
.icon-system:before {
  content: "\e91b";
}
.icon-test_form:before {
  content: "\e91c";
}
.icon-transactions:before {
  content: "\e91d";
}
.icon-user:before {
  content: "\e91e";
}
.icon-user_ico .path1:before {
  content: "\e91f";
  color: rgb(254, 254, 254);
}
.icon-user_ico .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-user_ico .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: #878A99;
}
.icon-users:before {
  content: "\e922";
}
.icon-warning:before {
  content: "\e923";
}
