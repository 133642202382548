.content {
  background: white;
  z-index: 0;
  overflow-x: auto;
}
::-webkit-scrollbar-track {
  padding-left: 50px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #a0a8c5;
}

.footer {
  border-top: 1px solid rgb(230, 230, 230);
}
.row1 {
  display: flex;
}
.noselect {
  cursor: default;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.noselect:focus {
  outline: none;
}
.reg-layout-body {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  margin-bottom: 20px;
}
.content input {
  background: rgba(255, 255, 255, 0.4);
  /*color: white;*/
  border-radius: 5px;
}
.form-control.on-click {
  background: rgba(255, 255, 255, 0.4) !important;
  border-radius: 15px !important;
}
.form-control option {
  color: black !important;
}
.form-control select {
  border-radius: 5px !important;
}
.form-control[id="select"] {
  border-radius: 5px;
  cursor: pointer;
}
.content button {
  border-radius: 4px;
}
.card {
  background: white !important;
}
.card .title {
  font-weight: bolder !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  border-width: 10px;
}
.card .title-sm {
  font-weight: bolder !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
  border-width: 10px;
  text-align: center;
}
.btn:not[id="2"] {
  border-radius: 25px !important;
}
.content .card1 {
  background-color: #181818 !important;
  color: white;
  border-radius: 12px;
  width: 400px;
  height: 250px;
}
.content .account1 {
  background-color: #002000 !important;
  border-radius: 50px;
}
/* .rate-modal {
  width: 70%;
} */
.popover {
  max-width: 30%;
  max-height: 100%;
}
.popover[id="1"] {
  text-align: left !important;
}
.account-style {
}
.form-group,
.col-md-3,
.col-md-2 {
  text-align: center !important;
  align-content: center !important;
  align-items: center !important;
}
.profile-label {
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Ubuntu Light", sans-serif;
  font-size: 20px;
  padding-left: 100px;
}
.profile-value {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
}
.transfer-select {
  color: #777777;
  text-transform: uppercase;
  font-size: 14px;
}
.ct-series-a .ct-slice-pie {
  fill: rgb(66, 195, 233) !important; /* info */
}
.ct-series-b .ct-slice-pie {
  fill: rgb(253, 40, 88) !important; /* danger */
}
.ct-series-c .ct-slice-pie {
  fill: rgb(250, 152, 22) !important; /* warning */
}
.empty {
  fill: darkslategrey !important;
}
.ct-label {
  text-shadow: 0 5px 15px black;
  font-size: 50px !important;
  fill: white !important;
}
.modal-width {
  width: 1000px !important;
}
.card .legend {
  white-space: pre;
  font-family: "Ubuntu Light", sans-serif;
  color: black !important;
  text-align: center !important;
  font-size: 20px !important;
}
.button-inline {
  border-width: 0;
  background: transparent;
  color: inherit;
  text-align: inherit;
  -webkit-font-smoothing: inherit;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}

/* .search-wrap {
  position: absolute;
  color: rgba(0, 0, 0, 0.4);
  background-color: transparent;
  height: 20px;
  width: 20px;
  right: 3px;
  top: 1px;
  border: none;
  font-size: 15px;
  text-align: right;
  padding-bottom: 10px;
  align-content: middle !important;
  border-radius: 0px !important;
}
.search-wrap:focus {
  outline: none !important;
}

.search {
  min-width: 100px;
  height: 100%;
  margin-bottom: 5px;
  transition: all .4s ease-in;
}
.search input {
  border: none;
} */
/*
.search .input {
  width: 80%;

  padding: 8px 0px 9px 0px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.1), inset 0 1px 2px rgba(0,0,0,0.3);
  border-radius: 5px;

  font: 13px Tahoma, Arial, sans-serif;
  color: black;
  outline: none;
}
.search .input:focus {
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2), inset 0 1px 2px rgba(0,0,0,0.4);
  background: #E8E8E8;
  color: #333;
}

.search .submit:hover {
  opacity: 0.8;
}
input[type="search"] {
  -webkit-appearance: none;
} */

.header1 {
  font-size: 20px;
}
.header2 {
  font-size: 18px;
  text-align: center;
}
.column {
  font-size: 15px;
  padding-left: 100px;
}
.labelHeader {
  margin-top: 40px;
  text-align: center;
}
.right {
  text-align: right;
}

.hid {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;

  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 9;
}

.help-tip p {
  /* The tooltip */
  display: none;
  text-align: left;
  background-color: rgb(89, 94, 97);
  padding: 10px;
  width: 280px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(175, 175, 175, 0.2);
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
}

.help-tip p:after {
  /* Prevents the tooltip from being hidden */
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: -10;
}

/* CSS animation */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.validate-error {
  color: #f06292;
  float: right;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.datetimepicker {
  border-color: hsl(0, 0%, 80%);
  background-color: hsl(0, 0%, 100%);
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  padding: 3px;
  width: 80%;
}
::-webkit-inner-spin-button {
  display: none;
}

.badge {
  min-width: 100px;
  font-weight: 400;
  border-radius: 5px;
  text-transform: capitalize;
  color: white;
}
.badge-pill {
  color: black;
}
.badge-rate {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #51bcda;
  border-radius: 5px;
}
.badge-role-type {
  background-color: transparent;
  color: black;
  font-weight: 1000;
  font-size: 14;
}
.badge-privilege-type {
  background-color: transparent;
  color: black;
  font-weight: 1000;
  font-size: 14;
}
.badge-order-paid,
.badge-statement-paid,
.badge-order-success {
  background-color: #26a69a;
}
.badge-order-unpaid,
.badge-order-pending,
.badge-order-3dwaiting {
  background-color: #d9b15a;
}
.badge-order-aborted,
.badge-order-failed {
  background-color: #ec5e6f;
}
.badge-order-refunded {
  background-color: #ec5e6f;
}
.badge-order-cancelled {
  background-color: #d9b15a;
}
.badge-order-subscribed {
  background-color: #1378cb;
}
.badge-statement-created {
  background-color: #8f939f;
}
.badge-statement-verified {
  background-color: #1378cb;
}
.badge-statement-paymentdue {
  background-color: #d9b15a;
}
.privilege-form-group {
  text-align: left !important;
}

.charge-form {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 15px 0;
  padding: 15px;
  overflow: hidden;
}
