@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-LightItalic.eot');
    src: local('Gotham Light Italic'), local('Gotham-LightItalic'),
        url('../fonts/gotham/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-LightItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BoldItalic.eot');
    src: local('Gotham Bold Italic'), local('Gotham-BoldItalic'),
        url('../fonts/gotham/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BoldItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-XLight.eot');
    src: local('Gotham ExtraLight'), local('Gotham-XLight'),
        url('../fonts/gotham/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-XLight.woff') format('woff'),
        url('../fonts/gotham/Gotham-XLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Thin.eot');
    src: local('Gotham Thin'), local('Gotham-Thin'),
        url('../fonts/gotham/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Thin.woff') format('woff'),
        url('../fonts/gotham/Gotham-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Book.eot');
    src: local('Gotham Book'), local('Gotham-Book'),
        url('../fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Book.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Book.woff') format('woff'),
        url('../fonts/gotham/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-ThinItalic.eot');
    src: local('Gotham Thin Italic'), local('Gotham-ThinItalic'),
        url('../fonts/gotham/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-ThinItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-ThinItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-MediumItalic.eot');
    src: local('Gotham Medium Italic'), local('Gotham-MediumItalic'),
        url('../fonts/gotham/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-MediumItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('../fonts/gotham/Gotham-UltraItalic.eot');
    src: local('Gotham Ultra Italic'), local('Gotham-UltraItalic'),
        url('../fonts/gotham/Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-UltraItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-UltraItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-UltraItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BookItalic.eot');
    src: local('Gotham Book Italic'), local('Gotham-BookItalic'),
        url('../fonts/gotham/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BookItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BlackItalic.eot');
    src: local('Gotham Black Italic'), local('Gotham-BlackItalic'),
        url('../fonts/gotham/Gotham-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-BlackItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BlackItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Light.eot');
    src: local('Gotham Light'), local('Gotham-Light'),
        url('../fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Light.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Light.woff') format('woff'),
        url('../fonts/gotham/Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Bold.eot');
    src: local('Gotham Bold'), local('Gotham-Bold'),
        url('../fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Bold.woff') format('woff'),
        url('../fonts/gotham/Gotham-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Medium.eot');
    src: local('Gotham Medium'), local('Gotham-Medium'),
        url('../fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Medium.woff') format('woff'),
        url('../fonts/gotham/Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('../fonts/gotham/Gotham-Ultra.eot');
    src: local('Gotham Ultra'), local('Gotham-Ultra'),
        url('../fonts/gotham/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Ultra.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Ultra.woff') format('woff'),
        url('../fonts/gotham/Gotham-Ultra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-XLightItalic.eot');
    src: local('Gotham ExtraLight Italic'), local('Gotham-XLightItalic'),
        url('../fonts/gotham/Gotham-XLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-XLightItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-XLightItalic.woff') format('woff'),
        url('../fonts/gotham/Gotham-XLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Black.eot');
    src: local('Gotham Black'), local('Gotham-Black'),
        url('../fonts/gotham/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gotham/Gotham-Black.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Black.woff') format('woff'),
        url('../fonts/gotham/Gotham-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

