/*!

 =========================================================
 * Light Bootstrap Dashboard React - v1.2.0
 * Based on Light Bootstrap Dashboard - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard-react
 * Copyright 2017 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*      light colors         */
.pagination-container {
  display: grid;
  grid-template-columns: 8fr 2fr; }
  .pagination-container .pagination-label {
    justify-self: end;
    align-self: center;
    padding-right: 25px;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 0; }

.pagination {
  box-shadow: none; }
  .pagination > li > a,
  .pagination > li > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-align: center;
    padding: 2px 12px;
    margin-right: 5px;
    border-radius: 4px;
    font-weight: 400; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #104a9d;
  border-color: #104a9d; }

.clickable {
  cursor: pointer; }

.icon.icon-edit:hover, .icon.icon-copy:hover {
  color: #C2C9D1; }

.icon.fa-trash-alt:hover {
  color: #FEC6C6; }

.icon.icon-eye:hover {
  color: #1378CB; }

.green {
  color: #5BB795; }

.gray {
  color: gray; }

.red {
  color: #EC5E6F; }

.blue {
  color: #104A9D; }

.orange {
  color: orange; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }

/*           Font Smoothing      */
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
  font-weight: 400; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 300;
  margin: 30px 0 15px; }

h1,
.h1 {
  font-size: 52px; }

h2,
.h2 {
  font-size: 36px; }

h3,
.h3 {
  font-size: 28px;
  margin: 20px 0 10px; }

h4,
.h4 {
  font-size: 22px;
  line-height: 30px; }

h5,
.h5 {
  font-size: 16px;
  margin-bottom: 15px; }

h6,
.h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase; }

p {
  font-size: 16px;
  line-height: 1.5; }

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #9a9a9a;
  font-weight: 300;
  line-height: 1.5; }

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%; }

h1 .subtitle {
  display: block;
  margin: 0 0 30px; }

.text-muted {
  color: #a0a8c5; }

.text-primary,
.text-primary:hover {
  color: #1d62f0 !important; }

.text-info,
.text-info:hover {
  color: #1dc7ea !important; }

.text-success,
.text-success:hover {
  color: #26a69a !important; }

.text-warning,
.text-warning:hover {
  color: #ff9500 !important; }

.text-danger,
.text-danger:hover {
  color: #ff4a55 !important; }

.highlight {
  background-color: orange;
  display: flex;
  flex-direction: column; }

/*     General overwrite     */
body {
  color: #878A99; }

body,
.wrapper,
.login-page {
  min-height: 100vh;
  position: relative;
  background-color: white; }

a {
  color: #1dc7ea; }
  a:hover, a:focus {
    color: #42d0ed;
    text-decoration: none; }

input:-internal-autofill-selected {
  background-color: transparent !important; }

input:-internal-autofill-selected {
  background-color: inherit !important; }

.link {
  color: #104A9D;
  text-decoration: underline; }
  .link:hover, .link:focus {
    color: #1378CB !important;
    text-decoration: underline; }

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0; }

.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important; }

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.sidebar .nav a, .sidebar .nav button .table > tbody > tr .td-actions .btn {
  -webkit-transition: all 150ms ease-in;
  -moz-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  transition: all 150ms ease-in; }

.btn {
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in; }

.fa {
  width: 18px;
  text-align: center; }

.margin-top {
  margin-top: 50px; }

.wrapper {
  position: relative;
  top: 0;
  height: 100vh; }

.tooltip.right .tooltip-arrow {
  border-right-color: gray; }

.tooltip-inner {
  background: gray; }

#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  color: #ffffff;
  font-weight: 400;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.1s ease-in;
  z-index: 2;
  background: #acafbc; }
  #sidebar .sidebar-wrapper {
    position: relative;
    margin-top: 105px;
    z-index: 4; }
  #sidebar .logo {
    background: #ffffff;
    width: 100%;
    height: 76px;
    position: relative;
    z-index: -1;
    border-right: 1px solid rgba(160, 168, 197, 0.3);
    box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.1); }
    #sidebar .logo a.logo-mini {
      float: left;
      text-align: center; }
      #sidebar .logo a.logo-mini img {
        width: 153px;
        height: 18px;
        margin-top: 29px;
        margin-left: 25px; }
    #sidebar .logo a.logo-normal {
      display: block; }
    #sidebar .logo .logo-img {
      display: inline-block;
      height: 76px;
      text-align: center; }
    #sidebar .logo .logo-img.slideOut {
      display: none; }
  #sidebar .logo.slideOut {
    background: #00b185;
    color: #ffffff;
    transition: all 0.1s ease-in; }
  #sidebar .logo.slideIn {
    background: #ffffff;
    color: #acafbc;
    transition: all 0.1s ease-in; }
  #sidebar .nav .nav-item {
    width: 100%;
    position: relative; }
    #sidebar .nav .nav-item.collapsed-menu .nav-menu {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
      #sidebar .nav .nav-item.collapsed-menu .nav-menu .nav-item {
        position: relative; }
        #sidebar .nav .nav-item.collapsed-menu .nav-menu .nav-item .nav-link {
          display: block;
          height: 36px; }
          #sidebar .nav .nav-item.collapsed-menu .nav-menu .nav-item .nav-link .menu-icon {
            width: 5px;
            height: 18px;
            font-size: 14px;
            margin-left: 50px;
            margin-top: 9px;
            margin-bottom: 9px; }
          #sidebar .nav .nav-item.collapsed-menu .nav-menu .nav-item .nav-link .menu-title {
            position: absolute;
            left: 67px;
            top: 9px;
            text-transform: none;
            align-items: center; }
        #sidebar .nav .nav-item.collapsed-menu .nav-menu .nav-item.active, #sidebar .nav .nav-item.collapsed-menu .nav-menu .nav-item:hover {
          background: #00b185;
          opacity: 1 !important; }
    #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) {
      padding: 0;
      margin: 0;
      list-style: none;
      border: none;
      border-radius: 0%;
      width: 205px;
      min-width: 150px;
      box-shadow: 5px 6px 7px 0px rgba(0, 0, 0, 0.175); }
      #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .nav-link .menu-icon {
        display: none; }
      #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .nav-link .menu-title {
        width: auto;
        position: absolute;
        left: 25px;
        top: 9px;
        font-weight: 400;
        text-transform: none;
        align-items: center;
        color: #5d6072; }
      #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .nav-link.active, #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .nav-link:hover {
        background: #f3f4f6; }
        #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .nav-link.active .menu-title, #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .nav-link:hover .menu-title {
          color: #00b185; }
      #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .menu-header {
        height: 36px;
        background: #00b185; }
        #sidebar .nav .nav-item.hover-open .nav-menu:not(.collapsing) .menu-header .menu-title {
          position: absolute;
          left: 25px;
          top: 9px;
          width: auto;
          display: inline-block;
          opacity: 1;
          margin: 0;
          line-height: 18px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-size: 14px;
          text-transform: uppercase;
          color: #ffffff; }
    #sidebar .nav .nav-item.hover-open .nav-menu.collapsing {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      background: #acafbc; }
      #sidebar .nav .nav-item.hover-open .nav-menu.collapsing .nav-item {
        position: relative; }
        #sidebar .nav .nav-item.hover-open .nav-menu.collapsing .nav-item .nav-link {
          display: block;
          height: 36px; }
          #sidebar .nav .nav-item.hover-open .nav-menu.collapsing .nav-item .nav-link .menu-icon {
            font-size: 14px;
            width: 5px;
            height: 18px;
            margin-left: 50px;
            margin-top: 9px;
            margin-bottom: 9px; }
          #sidebar .nav .nav-item.hover-open .nav-menu.collapsing .nav-item .nav-link .menu-title {
            position: absolute;
            left: 67px;
            top: 9px;
            text-transform: none;
            align-items: center; }
    #sidebar .nav .nav-item .nav-link {
      color: #ffffff;
      background-color: inherit;
      height: 36px;
      width: 100%;
      padding: 0px;
      border: none;
      text-align: left;
      opacity: 1 !important;
      border-radius: 0;
      box-shadow: none; }
      #sidebar .nav .nav-item .nav-link .menu-icon {
        color: #e8ecef;
        font-size: 26px;
        width: 26px;
        float: left;
        margin-left: 25px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center; }
      #sidebar .nav .nav-item .nav-link .menu-title {
        position: absolute;
        left: 60px;
        top: 9px;
        width: auto;
        display: inline-block;
        opacity: 0.8;
        margin: 0;
        line-height: 18px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase; }
      #sidebar .nav .nav-item .nav-link .menu-title.slideIn {
        transition: all 0.1s ease-in, color 0ms; }
      #sidebar .nav .nav-item .nav-link .menu-title.slideOut {
        visibility: hidden;
        transition: all 0.1s ease-in, color 0ms; }
      #sidebar .nav .nav-item .nav-link .menu-icon.slideIn {
        transition: all 0.1s ease-in, color 0ms; }
      #sidebar .nav .nav-item .nav-link .menu-icon.slideOut {
        font-size: 36px;
        width: 36px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 22px;
        transition: all 0.1s ease-in, color 0ms; }
      #sidebar .nav .nav-item .nav-link.active, #sidebar .nav .nav-item .nav-link:hover {
        background: #00b185;
        opacity: 1; }
        #sidebar .nav .nav-item .nav-link.active .menu-icon, #sidebar .nav .nav-item .nav-link:hover .menu-icon {
          color: #ffffff; }
        #sidebar .nav .nav-item .nav-link.active .menu-title, #sidebar .nav .nav-item .nav-link:hover .menu-title {
          opacity: 1; }
        #sidebar .nav .nav-item .nav-link.active .caret, #sidebar .nav .nav-item .nav-link:hover .caret {
          color: #ffffff; }
  #sidebar .nav .caret.slideIn {
    opacity: 1;
    transition: all 0.1s ease-in; }
  #sidebar .nav .caret.slideOut {
    display: none;
    transition: all 0.1s ease-in; }
  #sidebar .nav .caret {
    position: absolute;
    color: #e8ecef;
    width: 12px;
    height: 13px;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    border: none;
    transition: 0.1s all;
    margin-left: 166px;
    margin-top: 11px;
    transform: rotate(0deg); }
  #sidebar .nav .caret.open {
    transform: rotate(90deg); }

#sidebar::-webkit-scrollbar-track {
  padding-left: 50px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5; }

#sidebar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5; }

#sidebar::-webkit-scrollbar-thumb {
  background-color: #a0a8c5; }

#sidebar.slideOut {
  width: 75px;
  transition: all 0.1s ease-in;
  overflow: visible; }

#sidebar-hide-button-div {
  color: #acafbc;
  z-index: 3;
  cursor: pointer;
  transition: all 0.1s ease-in; }
  #sidebar-hide-button-div:hover {
    color: #acafbc; }

#sidebar-hide-button-div.slideOut {
  position: absolute;
  top: 26px;
  left: 25px; }

#sidebar-hide-button-div.slideIn {
  position: absolute;
  top: 26px;
  left: 209px; }

#sidebar-hide-button {
  font-size: 24px;
  height: 24px;
  width: 26px; }

#sidebar-hide-button-div.slideOut {
  color: #ffffff;
  transition: all 0.1s ease-in; }

#sidebar-hide-button-div.slideIn {
  color: #acafbc;
  transition: all 0.1s ease-in; }

#sidebar-hide-button.slideOut {
  transition: all 0.1s ease-in;
  transform: rotate(180deg); }

#sidebar-hide-button.slideIn {
  transition: all 0.1s ease-in;
  transform: rotate(0deg); }

#main-panel {
  background: #f9f9fc;
  position: relative;
  float: right;
  width: calc(100% - 260px);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  transition: all 0.1s ease-in; }
  #main-panel > .content {
    padding: 15px;
    height: calc(100% - 76px);
    min-height: calc(100% - 123px);
    background-color: #f9f9fc;
    flex: 1; }
  #main-panel > .footer {
    border-top: 1px solid #e7e7e7; }
  #main-panel .navbar {
    margin-bottom: 0;
    position: sticky;
    top: 0px;
    z-index: 2; }

body::-webkit-scrollbar {
  width: 16px; }

#main-panel.slideIn {
  width: calc(100% - 260px);
  transition: all 0.1s ease-in; }

#main-panel.slideOut {
  width: calc(100% - 75px);
  transition: all 0.1s ease-in; }

.btn {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  font-size: 12px;
  padding: 8px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: #bdbdbd;
  color: #bdbdbd; }
  .btn:hover, .btn:focus, .btn:active, .btn.active,
  .open > .btn.dropdown-toggle {
    background-color: transparent;
    color: #777777;
    border-color: #777777; }
  .btn.disabled, .btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn.disabled:active, .btn.disabled.active, .btn:disabled, .btn:disabled:hover, .btn:disabled:focus, .btn:disabled.focus, .btn:disabled:active, .btn:disabled.active, .btn[disabled], .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, .btn[disabled]:active, .btn[disabled].active,
  fieldset[disabled] .btn,
  fieldset[disabled] .btn:hover,
  fieldset[disabled] .btn:focus,
  fieldset[disabled] .btn.focus,
  fieldset[disabled] .btn:active,
  fieldset[disabled] .btn.active {
    background-color: transparent;
    border-color: #bdbdbd; }
  .btn.btn-fill {
    color: #ffffff;
    background-color: #bdbdbd;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn.btn-fill:hover, .btn.btn-fill:active, .btn.btn-fill.active,
    .open > .btn.btn-fill.dropdown-toggle {
      background-color: #777777;
      color: #ffffff; }
    .btn.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn .caret {
    border-top-color: #bdbdbd; }
  .btn:hover, .btn:focus {
    opacity: 1;
    filter: alpha(opacity=100);
    outline: 0 !important; }
  .btn:active, .btn.active,
  .open > .btn.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .btn.btn-icon {
    padding: 8px;
    padding: 8px 16px; }

.btn-copy {
  position: absolute !important;
  height: 20px;
  padding: 0% !important;
  border: 0;
  top: 100%;
  right: 83px;
  font-size: 11px; }

.btn-copy:active {
  background-color: transparent !important; }

.btn-table {
  border-width: 2px;
  background-color: transparent;
  border-style: solid;
  outline: 0 !important;
  opacity: 1;
  filter: alpha(opacity=100);
  border-color: gray;
  color: gray;
  transition: all 100ms ease-in; }
  .btn-table:hover, .btn-table:focus, .btn-table:active, .btn-table.active,
  .open > .btn-table.dropdown-toggle {
    background-color: transparent;
    color: #bdbdbd;
    border-color: #bdbdbd; }
  .btn-table.disabled, .btn-table.disabled:hover, .btn-table.disabled:focus, .btn-table.disabled.focus, .btn-table.disabled:active, .btn-table.disabled.active, .btn-table:disabled, .btn-table:disabled:hover, .btn-table:disabled:focus, .btn-table:disabled.focus, .btn-table:disabled:active, .btn-table:disabled.active, .btn-table[disabled], .btn-table[disabled]:hover, .btn-table[disabled]:focus, .btn-table[disabled].focus, .btn-table[disabled]:active, .btn-table[disabled].active,
  fieldset[disabled] .btn-table,
  fieldset[disabled] .btn-table:hover,
  fieldset[disabled] .btn-table:focus,
  fieldset[disabled] .btn-table.focus,
  fieldset[disabled] .btn-table:active,
  fieldset[disabled] .btn-table.active {
    background-color: transparent;
    border-color: gray; }
  .btn-table.btn-fill {
    color: #ffffff;
    background-color: gray;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-table.btn-fill:hover, .btn-table.btn-fill:active, .btn-table.btn-fill.active,
    .open > .btn-table.btn-fill.dropdown-toggle {
      background-color: #bdbdbd;
      color: #ffffff; }
    .btn-table.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-table .caret {
    border-top-color: gray; }
  .btn-table.btn-danger {
    border-color: #fb3743;
    color: #fb3743; }
    .btn-table.btn-danger:hover, .btn-table.btn-danger:focus, .btn-table.btn-danger:active, .btn-table.btn-danger.active,
    .open > .btn-table.btn-danger.dropdown-toggle {
      background-color: transparent;
      color: #ff989e;
      border-color: #ff989e; }
    .btn-table.btn-danger.disabled, .btn-table.btn-danger.disabled:hover, .btn-table.btn-danger.disabled:focus, .btn-table.btn-danger.disabled.focus, .btn-table.btn-danger.disabled:active, .btn-table.btn-danger.disabled.active, .btn-table.btn-danger:disabled, .btn-table.btn-danger:disabled:hover, .btn-table.btn-danger:disabled:focus, .btn-table.btn-danger:disabled.focus, .btn-table.btn-danger:disabled:active, .btn-table.btn-danger:disabled.active, .btn-table.btn-danger[disabled], .btn-table.btn-danger[disabled]:hover, .btn-table.btn-danger[disabled]:focus, .btn-table.btn-danger[disabled].focus, .btn-table.btn-danger[disabled]:active, .btn-table.btn-danger[disabled].active,
    fieldset[disabled] .btn-table.btn-danger,
    fieldset[disabled] .btn-table.btn-danger:hover,
    fieldset[disabled] .btn-table.btn-danger:focus,
    fieldset[disabled] .btn-table.btn-danger.focus,
    fieldset[disabled] .btn-table.btn-danger:active,
    fieldset[disabled] .btn-table.btn-danger.active {
      background-color: transparent;
      border-color: #fb3743; }
    .btn-table.btn-danger.btn-fill {
      color: #ffffff;
      background-color: #fb3743;
      opacity: 1;
      filter: alpha(opacity=100); }
      .btn-table.btn-danger.btn-fill:hover, .btn-table.btn-danger.btn-fill:active, .btn-table.btn-danger.btn-fill.active,
      .open > .btn-table.btn-danger.btn-fill.dropdown-toggle {
        background-color: #ff989e;
        color: #ffffff; }
      .btn-table.btn-danger.btn-fill .caret {
        border-top-color: #ffffff; }
    .btn-table.btn-danger .caret {
      border-top-color: #fb3743; }
  .btn-table.btn-fill, .btn-table.btn-fill:hover, .btn-table.btn-fill:focus {
    color: #ffffff; }
  .btn-table.btn-status {
    border: none;
    min-width: 100px; }
    .btn-table.btn-status.verified {
      border-color: #1378cb;
      color: #1378cb; }
      .btn-table.btn-status.verified:hover, .btn-table.btn-status.verified:focus, .btn-table.btn-status.verified:active, .btn-table.btn-status.verified.active,
      .open > .btn-table.btn-status.verified.dropdown-toggle {
        background-color: transparent;
        color: #3f95db;
        border-color: #3f95db; }
      .btn-table.btn-status.verified.disabled, .btn-table.btn-status.verified.disabled:hover, .btn-table.btn-status.verified.disabled:focus, .btn-table.btn-status.verified.disabled.focus, .btn-table.btn-status.verified.disabled:active, .btn-table.btn-status.verified.disabled.active, .btn-table.btn-status.verified:disabled, .btn-table.btn-status.verified:disabled:hover, .btn-table.btn-status.verified:disabled:focus, .btn-table.btn-status.verified:disabled.focus, .btn-table.btn-status.verified:disabled:active, .btn-table.btn-status.verified:disabled.active, .btn-table.btn-status.verified[disabled], .btn-table.btn-status.verified[disabled]:hover, .btn-table.btn-status.verified[disabled]:focus, .btn-table.btn-status.verified[disabled].focus, .btn-table.btn-status.verified[disabled]:active, .btn-table.btn-status.verified[disabled].active,
      fieldset[disabled] .btn-table.btn-status.verified,
      fieldset[disabled] .btn-table.btn-status.verified:hover,
      fieldset[disabled] .btn-table.btn-status.verified:focus,
      fieldset[disabled] .btn-table.btn-status.verified.focus,
      fieldset[disabled] .btn-table.btn-status.verified:active,
      fieldset[disabled] .btn-table.btn-status.verified.active {
        background-color: transparent;
        border-color: #1378cb; }
      .btn-table.btn-status.verified.btn-fill {
        color: #ffffff;
        background-color: #1378cb;
        opacity: 1;
        filter: alpha(opacity=100); }
        .btn-table.btn-status.verified.btn-fill:hover, .btn-table.btn-status.verified.btn-fill:active, .btn-table.btn-status.verified.btn-fill.active,
        .open > .btn-table.btn-status.verified.btn-fill.dropdown-toggle {
          background-color: #3f95db;
          color: #ffffff; }
        .btn-table.btn-status.verified.btn-fill .caret {
          border-top-color: #ffffff; }
      .btn-table.btn-status.verified .caret {
        border-top-color: #1378cb; }
    .btn-table.btn-status.paymentdue {
      border-color: #d9b15a;
      color: #d9b15a; }
      .btn-table.btn-status.paymentdue:hover, .btn-table.btn-status.paymentdue:focus, .btn-table.btn-status.paymentdue:active, .btn-table.btn-status.paymentdue.active,
      .open > .btn-table.btn-status.paymentdue.dropdown-toggle {
        background-color: transparent;
        color: #e2c688;
        border-color: #e2c688; }
      .btn-table.btn-status.paymentdue.disabled, .btn-table.btn-status.paymentdue.disabled:hover, .btn-table.btn-status.paymentdue.disabled:focus, .btn-table.btn-status.paymentdue.disabled.focus, .btn-table.btn-status.paymentdue.disabled:active, .btn-table.btn-status.paymentdue.disabled.active, .btn-table.btn-status.paymentdue:disabled, .btn-table.btn-status.paymentdue:disabled:hover, .btn-table.btn-status.paymentdue:disabled:focus, .btn-table.btn-status.paymentdue:disabled.focus, .btn-table.btn-status.paymentdue:disabled:active, .btn-table.btn-status.paymentdue:disabled.active, .btn-table.btn-status.paymentdue[disabled], .btn-table.btn-status.paymentdue[disabled]:hover, .btn-table.btn-status.paymentdue[disabled]:focus, .btn-table.btn-status.paymentdue[disabled].focus, .btn-table.btn-status.paymentdue[disabled]:active, .btn-table.btn-status.paymentdue[disabled].active,
      fieldset[disabled] .btn-table.btn-status.paymentdue,
      fieldset[disabled] .btn-table.btn-status.paymentdue:hover,
      fieldset[disabled] .btn-table.btn-status.paymentdue:focus,
      fieldset[disabled] .btn-table.btn-status.paymentdue.focus,
      fieldset[disabled] .btn-table.btn-status.paymentdue:active,
      fieldset[disabled] .btn-table.btn-status.paymentdue.active {
        background-color: transparent;
        border-color: #d9b15a; }
      .btn-table.btn-status.paymentdue.btn-fill {
        color: #ffffff;
        background-color: #d9b15a;
        opacity: 1;
        filter: alpha(opacity=100); }
        .btn-table.btn-status.paymentdue.btn-fill:hover, .btn-table.btn-status.paymentdue.btn-fill:active, .btn-table.btn-status.paymentdue.btn-fill.active,
        .open > .btn-table.btn-status.paymentdue.btn-fill.dropdown-toggle {
          background-color: #e2c688;
          color: #ffffff; }
        .btn-table.btn-status.paymentdue.btn-fill .caret {
          border-top-color: #ffffff; }
      .btn-table.btn-status.paymentdue .caret {
        border-top-color: #d9b15a; }
    .btn-table.btn-status.paid {
      border-color: #26a69a;
      color: #26a69a; }
      .btn-table.btn-status.paid:hover, .btn-table.btn-status.paid:focus, .btn-table.btn-status.paid:active, .btn-table.btn-status.paid.active,
      .open > .btn-table.btn-status.paid.dropdown-toggle {
        background-color: transparent;
        color: #5acfc4;
        border-color: #5acfc4; }
      .btn-table.btn-status.paid.disabled, .btn-table.btn-status.paid.disabled:hover, .btn-table.btn-status.paid.disabled:focus, .btn-table.btn-status.paid.disabled.focus, .btn-table.btn-status.paid.disabled:active, .btn-table.btn-status.paid.disabled.active, .btn-table.btn-status.paid:disabled, .btn-table.btn-status.paid:disabled:hover, .btn-table.btn-status.paid:disabled:focus, .btn-table.btn-status.paid:disabled.focus, .btn-table.btn-status.paid:disabled:active, .btn-table.btn-status.paid:disabled.active, .btn-table.btn-status.paid[disabled], .btn-table.btn-status.paid[disabled]:hover, .btn-table.btn-status.paid[disabled]:focus, .btn-table.btn-status.paid[disabled].focus, .btn-table.btn-status.paid[disabled]:active, .btn-table.btn-status.paid[disabled].active,
      fieldset[disabled] .btn-table.btn-status.paid,
      fieldset[disabled] .btn-table.btn-status.paid:hover,
      fieldset[disabled] .btn-table.btn-status.paid:focus,
      fieldset[disabled] .btn-table.btn-status.paid.focus,
      fieldset[disabled] .btn-table.btn-status.paid:active,
      fieldset[disabled] .btn-table.btn-status.paid.active {
        background-color: transparent;
        border-color: #26a69a; }
      .btn-table.btn-status.paid.btn-fill {
        color: #ffffff;
        background-color: #26a69a;
        opacity: 1;
        filter: alpha(opacity=100); }
        .btn-table.btn-status.paid.btn-fill:hover, .btn-table.btn-status.paid.btn-fill:active, .btn-table.btn-status.paid.btn-fill.active,
        .open > .btn-table.btn-status.paid.btn-fill.dropdown-toggle {
          background-color: #5acfc4;
          color: #ffffff; }
        .btn-table.btn-status.paid.btn-fill .caret {
          border-top-color: #ffffff; }
      .btn-table.btn-status.paid .caret {
        border-top-color: #26a69a; }

.btn-export, #table.btn-export, #reportTable.btn-export, #pieChart.btn-export {
  float: right;
  padding: 2px 6px 2px 6px;
  margin: 2px 4px 2px 4px; }

#table.btn-export {
  margin: -25px 10px 10px 0px;
  padding: 8px 16px; }

#reportTable.btn-export {
  margin-right: 15px;
  margin-top: 15px;
  padding: 8px 16px; }

#pieChart.btn-export {
  margin-top: -20px; }

.btn-primary {
  border-color: #00b185;
  color: #00b185; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-color: transparent;
    color: #00b185;
    border-color: #00b185; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary:disabled, .btn-primary:disabled:hover, .btn-primary:disabled:focus, .btn-primary:disabled.focus, .btn-primary:disabled:active, .btn-primary:disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: transparent;
    border-color: #00b185; }
  .btn-primary.btn-fill {
    color: #ffffff;
    background-color: #00b185;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-primary.btn-fill:hover, .btn-primary.btn-fill:active, .btn-primary.btn-fill.active,
    .open > .btn-primary.btn-fill.dropdown-toggle {
      background-color: #00b185;
      color: #ffffff; }
    .btn-primary.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-primary .caret {
    border-top-color: #00b185; }

.btn-success {
  border-color: #26a69a;
  color: #26a69a; }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-color: transparent;
    color: #009688;
    border-color: #009688; }
  .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success:disabled, .btn-success:disabled:hover, .btn-success:disabled:focus, .btn-success:disabled.focus, .btn-success:disabled:active, .btn-success:disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background-color: transparent;
    border-color: #26a69a; }
  .btn-success.btn-fill {
    color: #ffffff;
    background-color: #26a69a;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-success.btn-fill:hover, .btn-success.btn-fill:active, .btn-success.btn-fill.active,
    .open > .btn-success.btn-fill.dropdown-toggle {
      background-color: #009688;
      color: #ffffff; }
    .btn-success.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-success .caret {
    border-top-color: #26a69a; }

.btn-info {
  border-color: #1dc7ea;
  color: #1dc7ea; }
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-color: transparent;
    color: #42d0ed;
    border-color: #42d0ed; }
  .btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info:disabled, .btn-info:disabled:hover, .btn-info:disabled:focus, .btn-info:disabled.focus, .btn-info:disabled:active, .btn-info:disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    background-color: transparent;
    border-color: #1dc7ea; }
  .btn-info.btn-fill {
    color: #ffffff;
    background-color: #1dc7ea;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-info.btn-fill:hover, .btn-info.btn-fill:active, .btn-info.btn-fill.active,
    .open > .btn-info.btn-fill.dropdown-toggle {
      background-color: #42d0ed;
      color: #ffffff; }
    .btn-info.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-info .caret {
    border-top-color: #1dc7ea; }

.btn-warning {
  border-color: #ff9500;
  color: #ff9500; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-color: transparent;
    color: #ed8d00;
    border-color: #ed8d00; }
  .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning:disabled, .btn-warning:disabled:hover, .btn-warning:disabled:focus, .btn-warning:disabled.focus, .btn-warning:disabled:active, .btn-warning:disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background-color: transparent;
    border-color: #ff9500; }
  .btn-warning.btn-fill {
    color: #ffffff;
    background-color: #ff9500;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-warning.btn-fill:hover, .btn-warning.btn-fill:active, .btn-warning.btn-fill.active,
    .open > .btn-warning.btn-fill.dropdown-toggle {
      background-color: #ed8d00;
      color: #ffffff; }
    .btn-warning.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-warning .caret {
    border-top-color: #ff9500; }

.btn-danger {
  border-color: #ff4a55;
  color: #ff4a55; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-color: transparent;
    color: #ee2d20;
    border-color: #ee2d20; }
  .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger:disabled, .btn-danger:disabled:hover, .btn-danger:disabled:focus, .btn-danger:disabled.focus, .btn-danger:disabled:active, .btn-danger:disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background-color: transparent;
    border-color: #ff4a55; }
  .btn-danger.btn-fill {
    color: #ffffff;
    background-color: #ff4a55;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-danger.btn-fill:hover, .btn-danger.btn-fill:active, .btn-danger.btn-fill.active,
    .open > .btn-danger.btn-fill.dropdown-toggle {
      background-color: #ee2d20;
      color: #ffffff; }
    .btn-danger.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-danger .caret {
    border-top-color: #ff4a55; }

.btn-neutral {
  border-color: #ffffff;
  color: #ffffff; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active,
  .open > .btn-neutral.dropdown-toggle {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff; }
  .btn-neutral.disabled, .btn-neutral.disabled:hover, .btn-neutral.disabled:focus, .btn-neutral.disabled.focus, .btn-neutral.disabled:active, .btn-neutral.disabled.active, .btn-neutral:disabled, .btn-neutral:disabled:hover, .btn-neutral:disabled:focus, .btn-neutral:disabled.focus, .btn-neutral:disabled:active, .btn-neutral:disabled.active, .btn-neutral[disabled], .btn-neutral[disabled]:hover, .btn-neutral[disabled]:focus, .btn-neutral[disabled].focus, .btn-neutral[disabled]:active, .btn-neutral[disabled].active,
  fieldset[disabled] .btn-neutral,
  fieldset[disabled] .btn-neutral:hover,
  fieldset[disabled] .btn-neutral:focus,
  fieldset[disabled] .btn-neutral.focus,
  fieldset[disabled] .btn-neutral:active,
  fieldset[disabled] .btn-neutral.active {
    background-color: transparent;
    border-color: #ffffff; }
  .btn-neutral.btn-fill {
    color: #ffffff;
    background-color: #ffffff;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:active, .btn-neutral.btn-fill.active,
    .open > .btn-neutral.btn-fill.dropdown-toggle {
      background-color: #ffffff;
      color: #ffffff; }
    .btn-neutral.btn-fill .caret {
      border-top-color: #ffffff; }
  .btn-neutral .caret {
    border-top-color: #ffffff; }
  .btn-neutral:active, .btn-neutral.active,
  .open > .btn-neutral.dropdown-toggle {
    background-color: #ffffff;
    color: #bdbdbd; }
  .btn-neutral.btn-fill, .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus {
    color: #bdbdbd; }
  .btn-neutral.btn-simple:active, .btn-neutral.btn-simple.active {
    background-color: transparent; }

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 9px 18px; }
  .btn-round.btn-icon {
    padding: 9px; }

.btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px; }
  .btn-simple.btn-icon {
    padding: 8px; }

.btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 400; }
  .btn-lg.btn-round {
    padding: 15px 30px; }
  .btn-lg.btn-simple {
    padding: 16px 30px; }

.btn-sm {
  font-size: 13px;
  border-radius: 3px;
  padding: 5px 10px; }
  .btn-sm.btn-round {
    padding: 6px 10px; }
  .btn-sm.btn-simple {
    padding: 7px 10px; }

.btn-xs {
  font-size: 13px;
  border-radius: 3px;
  padding: 1px 5px; }
  .btn-xs.btn-round {
    padding: 2px 5px; }
  .btn-xs.btn-simple {
    padding: 3px 5px; }

.btn-wd {
  min-width: 140px; }

.btn-group.select {
  width: 100%; }

.btn-group.select .btn {
  text-align: left; }

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px; }

.search-button {
  margin: 0px 0px 10px 10px; }

.back-button {
  position: relative;
  border: none !important;
  width: 20px;
  height: 20px;
  text-align: center !important;
  align-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  bottom: 15px;
  left: 15px; }
  .back-button:hover, .back-button:active {
    background: transparent !important; }
  .back-button .icon {
    position: relative;
    padding: 0 !important;
    width: 20px;
    height: 20px;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 20px;
    color: #757575 !important; }
    @media screen and (max-width: 1000px) {
      .back-button .icon {
        font-size: 15px;
        left: -5px; } }
    .back-button .icon:hover, .back-button .icon:active {
      color: #545761 !important; }
  @media screen and (max-width: 1000px) {
    .back-button {
      width: 15px;
      height: 15px;
      top: 2px;
      left: 0px; } }

.modal-buttons {
  margin: 10px 0; }

.form-control::-moz-placeholder {
  color: #dddddd;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control:-moz-placeholder {
  color: #dddddd;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control::-webkit-input-placeholder {
  color: #dddddd;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control:-ms-input-placeholder {
  color: #dddddd;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .form-control:focus {
    background-color: #ffffff;
    border: 1px solid #aaaaaa;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
    color: #333333; }
  .has-success .form-control,
  .has-error .form-control,
  .has-success .form-control:focus,
  .has-error .form-control:focus {
    border-color: #e3e3e3;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .has-success .form-control {
    color: #000000; }
  .has-success .form-control:focus {
    border-color: #26a69a; }
  .has-error .form-control {
    color: #000000; }
  .has-error .form-control:focus {
    border-color: #ff4a55; }
  .form-control + .form-control-feedback {
    border-radius: 6px;
    font-size: 14px;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle; }
  .open .form-control {
    border-radius: 4px 4px 0 0;
    border-bottom-color: transparent; }

.input-lg {
  height: 55px;
  padding: 14px 30px; }

.has-error .form-control-feedback {
  color: #ff4a55; }

.has-success .form-control-feedback {
  color: #26a69a; }

.input-group-addon {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px; }
  .has-success .input-group-addon,
  .has-error .input-group-addon {
    background-color: #ffffff;
    border: 1px solid #e3e3e3; }
  .has-error .form-control:focus + .input-group-addon {
    border-color: #ff4a55;
    color: #ff4a55; }
  .has-success .form-control:focus + .input-group-addon {
    border-color: #26a69a;
    color: #26a69a; }
  .form-control:focus + .input-group-addon,
  .form-control:focus ~ .input-group-addon {
    background-color: #ffffff;
    border-color: #9a9a9a; }

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f5f5f5;
  color: #bdbdbd;
  cursor: not-allowed; }

.input-group-btn .btn {
  border-width: 1px;
  padding: 9px 16px; }

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: #dddddd; }

.input-group-btn:last-child > .btn {
  margin-left: 0; }

.input-group-focus .input-group-addon {
  border-color: #9a9a9a; }

.search-box {
  margin-bottom: 5px; }

.password-strength-meter {
  text-align: left; }

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px; }

.password-strength-meter-progress::-moz-progress-bar {
  background-color: #eee;
  border-radius: 3px; }

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px; }

.password-strength-meter-label {
  font-size: 14px; }

.password-strength-meter-progress::-moz-progress-bar {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%; }

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%; }

.strength-Weak::-moz-progress-bar {
  background-color: #F25F5C; }

.strength-Weak::-webkit-progress-value {
  background-color: #F25F5C; }

.strength-Fair::-webkit-progress-value {
  background-color: #FFE066; }

.strength-Fair::-moz-progress-bar {
  background-color: #FFE066; }

.strength-Good::-webkit-progress-value {
  background-color: #247BA0; }

.strength-Good::-moz-progress-bar {
  background-color: #247BA0; }

.strength-Strong::-webkit-progress-value {
  background-color: #70C1B3; }

.strength-Strong::-moz-progress-bar {
  background-color: #70C1B3; }

.control-label {
  margin-top: 10px;
  margin-bottom: 0 !important; }

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.alert {
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  padding: 10px 15px;
  font-size: 14px; }
  .container .alert {
    border-radius: 4px; }
  .navbar .alert {
    border-radius: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;
    z-index: 3; }
  .navbar:not(.navbar-transparent) .alert {
    top: 70px; }
  .alert span[data-notify="icon"] {
    font-size: 30px;
    display: block;
    left: 15px;
    position: absolute;
    top: 50%;
    margin-top: -15px; }
  .alert button.close {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -13px;
    z-index: 1033;
    background-color: #FFFFFF;
    display: block;
    border-radius: 50%;
    opacity: .4;
    line-height: 20px;
    font-size: 12px;
    width: 25px;
    height: 25px;
    outline: 0 !important;
    text-align: center;
    padding: 3px;
    font-weight: 300; }
    .alert button.close:hover {
      opacity: .55; }
  .alert .close ~ span {
    display: block;
    max-width: 89%; }
  .alert[data-notify="container"] {
    padding: 10px 10px 10px 20px;
    border-radius: 4px; }
  .alert.alert-with-icon {
    padding-left: 65px; }
  .alert a, .alert a:hover, .alert a:focus {
    text-decoration: underline;
    color: white; }

.alert-info {
  background-color: #63d8f1; }

.alert-success {
  background-color: #a1e82c; }

.alert-warning {
  background-color: #ffbc67; }

.alert-danger {
  background-color: #fc727a; }

.swal-modal {
  padding-bottom: 24px;
  width: 365px; }
  .swal-modal.error .swal-title {
    color: #F06292; }
  .swal-modal .swal-icon:first-child {
    margin-top: 25px;
    margin-bottom: 0px; }
  .swal-modal .swal-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 35px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: -10px;
    padding: 0px; }
  .swal-modal .swal-title:not(:last-child) {
    margin-bottom: 20px; }
  .swal-modal .swal-text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: #5D6072; }
  .swal-modal .swal-button {
    background: #ACAFBC;
    border-radius: 4px;
    height: 40px;
    min-width: 100px; }
  .swal-modal .swal-footer {
    text-align: center;
    padding: 20px 0 0 0; }

.table {
  border-collapse: separate !important; }
  .table td {
    border-bottom: 1px solid #e7eaf0;
    border-left: 1px solid #e7eaf0;
    word-break: break-all;
    min-width: 60px; }
  .table td,
  .table td > a,
  .table th {
    font-family: "Gotham", sans-serif !important;
    font-weight: 400; }
  .table tr {
    border-top: 1px solid #e7eaf0; }
  .table td:last-child {
    border-right: 1px solid #e7eaf0; }
  .table tr:last-child td:last-child {
    border-bottom-right-radius: 4px; }
  .table tr:last-child td:first-child {
    border-bottom-left-radius: 4px; }
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    padding: 12px 10px;
    vertical-align: middle;
    border-top: none; }
  .table > tbody > tr > td {
    font-size: 12px;
    line-height: 16px; }
  .table tbody .icon {
    font-size: 20px; }
    .table tbody .icon.icon-edit {
      font-size: 23px; }
  .table tbody button {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 8px;
    border-width: 1px; }
  .table > thead > tr > th {
    font-size: 12px;
    color: #9a9a9a;
    font-weight: 400;
    padding: 3px 11px;
    font-weight: 500; }
  .table > thead tr.search-row th {
    padding: 0 10px 15px 0; }
    .table > thead tr.search-row th.searchClosed {
      display: none; }
    .table > thead tr.search-row th .align-center {
      text-align: center; }
  .table > thead tr.search-row input {
    border: 1px solid #c8c8c8;
    border-radius: 2px;
    text-align: left;
    line-height: 18px;
    color: #c8c8c8;
    font-size: 12px;
    background: none; }
    .table > thead tr.search-row input.text-input {
      padding: 3px 22px;
      width: 100%; }
    .table > thead tr.search-row input.daterange-input {
      padding: 4px 0px 4px 22px; }
  .table > thead tr.search-row .icon {
    color: #c8c8c8;
    font-size: 17px;
    margin-top: 4px; }
    .table > thead tr.search-row .icon.icon-delete:hover {
      color: gray; }
  .table > thead tr.search-row input::placeholder {
    color: #c8c8c8;
    text-transform: capitalize; }
  .table > thead tr.search-row .select__control {
    color: #c8c8c8;
    line-height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    border: none !important;
    box-sizing: border-box;
    border-radius: 2px;
    min-height: 26px !important;
    min-width: 90px;
    box-shadow: none !important; }
  .table > thead tr.search-row .type-select .select__control {
    min-width: 130px; }
  .table > thead tr.search-row .select__control--menu-is-open,
  .table > thead tr.search-row .select__control--is--focused {
    border: none !important;
    border-color: unset !important;
    box-shadow: none !important;
    outline-color: none !important; }
  .table > thead tr.search-row .select__indicator {
    padding: 0 3px;
    color: #cccccc !important; }
  .table > thead tr.search-row .select__indicator-separator {
    display: none; }
  .table > thead tr.search-row .select__value-container {
    padding: 0;
    color: #c8c8c8; }
  .table > thead tr.search-row .select__placeholder {
    color: #c8c8c8;
    top: 55%;
    text-transform: none;
    padding-left: 6px; }
  .table > thead tr.search-row .select__input {
    padding-left: 6px; }
  .table > thead tr.search-row .select__single-value {
    color: #c8c8c8; }
  .table > thead tr.search-row .search {
    font-weight: 400; }
    .table > thead tr.search-row .search.search-input {
      border: 1px solid #c8c8c8;
      border-radius: 2px; }
    .table > thead tr.search-row .search .input-group-addon {
      border: none !important;
      padding: 2px 2px; }
    .table > thead tr.search-row .search .form-control {
      border: none !important;
      padding: 2px 0px 2px 5px;
      height: 26px; }
    .table > thead tr.search-row .search .select__menu-list {
      max-height: 230px; }
  .table > thead tr.title-row {
    background-color: #f5f8fd; }
    .table > thead tr.title-row > th {
      border-left: 1px solid #e7eaf0;
      border-top: 1px solid #e7eaf0; }
    .table > thead tr.title-row > th:last-child {
      border-right: 1px solid #e7eaf0; }
  .table > thead tr > th {
    text-transform: uppercase;
    border: none; }
  .table .td-actions .btn {
    opacity: 0.36;
    filter: alpha(opacity=36); }
    .table .td-actions .btn.btn-xs {
      padding-left: 3px;
      padding-right: 3px; }
  .table .td-actions {
    min-width: 90px; }
  .table > tbody > tr {
    position: relative;
    overflow: scroll; }
    .table > tbody > tr:hover .td-actions .btn {
      opacity: 1;
      filter: alpha(opacity=100); }

.table.scroll {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0px; }

.table.detailInfo td {
  border-bottom: none; }

.table.detailInfo tr {
  font-size: 15px;
  font-weight: 400; }

.table.detailInfo td:not(:nth-child(1)),
.table.detailInfo th:not(:nth-child(1)) {
  border-left: none; }

.table.detailInfo td:last-child {
  border-right: none; }

.main-table {
  background-color: #ffffff;
  width: 100%;
  padding: 22px; }
  .main-table .group-buttons {
    display: grid;
    grid-template-columns: 5fr 2fr;
    margin-bottom: 22px; }
    .main-table .group-buttons .main-buttons {
      display: grid;
      grid-template-columns: 5fr 5fr;
      justify-self: start; }
      .main-table .group-buttons .main-buttons button {
        margin-right: 11px; }
      .main-table .group-buttons .main-buttons .filter {
        width: 100px;
        padding: 0; }
        .main-table .group-buttons .main-buttons .filter .icon {
          font-size: 14px;
          position: relative;
          top: 1px; }
      .main-table .group-buttons .main-buttons .icon {
        font-size: 13px;
        margin-left: 5px; }
    .main-table .group-buttons .secondary-buttons {
      justify-self: end; }
      .main-table .group-buttons .secondary-buttons button {
        margin-left: 11px; }
    .main-table .group-buttons button {
      height: 32px;
      padding: 8px;
      line-height: 16px;
      font-size: 12px;
      text-transform: uppercase;
      box-shadow: none;
      border: none; }
  .main-table .table-navigation {
    display: grid;
    grid-template-columns: 1fr 150px; }
    .main-table .table-navigation .page-size-container {
      display: grid;
      grid-template-columns: 80px 70px;
      justify-self: end;
      align-self: center; }
      .main-table .table-navigation .page-size-container label {
        align-self: center;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 0; }
      .main-table .table-navigation .page-size-container .select__control {
        line-height: 18px;
        font-size: 13px;
        border: #c8c8c8 1px solid;
        min-height: 32px !important;
        min-width: 70px; }
      .main-table .table-navigation .page-size-container .select__indicator-separator {
        display: none; }

.dropdown-navigation-button {
  height: 32px;
  padding: 7px;
  line-height: 16px;
  font-size: 12px;
  text-transform: uppercase;
  box-shadow: none;
  border: none;
  margin-bottom: 15px; }

.modal-creator {
  width: 70vw;
  max-width: none; }

.modal-creator {
  width: 90vw; }

form {
  font-size: 14px; }
  form .form-control {
    height: 28px;
    font-size: 14px; }
  form textarea.form-control {
    height: auto; }
  form .form-label {
    text-align: left !important; }
  form .input-group-text {
    height: 28px;
    font-size: 14px; }
  form .select__control {
    min-height: 28px; }
    form .select__control .select__indicators {
      height: 28px; }
      form .select__control .select__indicators .select__indicator {
        padding: 4px; }
  form .select__option {
    padding: 4px;
    font-size: 12px;
    text-align: left; }
  form .select__menu {
    z-index: 999; }
  form .select__placeholder {
    color: #dddddd;
    opacity: 1;
    filter: alpha(opacity=100); }
  form .btn {
    font-size: 14px; }

input[type="checkbox"] {
  margin-right: 8px; }

/*      Checkbox and radio         */
.checkbox,
.radio {
  margin-bottom: 12px; }

.checkbox label,
.radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  margin-bottom: 0; }

.checkbox label::before,
.checkbox label::after {
  font-family: 'FontAwesome';
  content: "\f096";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  cursor: pointer;
  line-height: 19px;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 1px;
  color: #dddddd;
  transition: color 0.2s linear;
  padding: 1px; }

.checkbox label::after {
  content: "";
  text-align: center;
  opacity: 1;
  left: 1px;
  color: #dddddd; }

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  margin-left: 0; }

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f046"; }

.checkbox input[type="checkbox"]:checked + label::after {
  color: #1dc7ea; }

.checkbox input[type="checkbox"]:checked + label::before {
  opacity: 0; }

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label,
.checkbox input[type="checkbox"]:disabled:checked + label::after {
  color: #dddddd; }

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label::after {
  cursor: not-allowed; }

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label {
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  padding-left: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

.radio label::before,
.radio label::after {
  font-family: 'FontAwesome';
  content: "\f10c";
  font-size: 20px;
  height: 20px;
  width: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  line-height: 19px;
  left: 0;
  top: 0;
  color: #dddddd;
  padding: 1px;
  transition: color 0.2s linear; }

.radio input[type="radio"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f192";
  color: #dddddd; }

.radio input[type="radio"]:checked + label::after {
  color: #1dc7ea; }

.radio input[type="radio"]:disabled + label {
  color: #ddd; }

.radio input[type="radio"]:disabled + label::before,
.radio input[type="radio"]:disabled + label::after {
  color: #ddd; }

.radio.radio-inline {
  margin-top: 0; }

/**
  * bootstrap-switch - Turn checkboxes and radio buttons into toggle switches.
  *
  * @version v3.3.4
  * @homepage https://bttstrp.github.io/bootstrap-switch
  * @author Mattia Larentis <mattia@larentis.eu> (http://larentis.eu)
  * @license Apache-2.0
  */
.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid;
  border-color: #ccc;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px; }

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #337ab7; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #5bc0de; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #5cb85c; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: #f0ad4e;
  color: #fff; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #d9534f; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #000;
  background: #eeeeee; }

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #333;
  background: #fff; }

.bootstrap-switch span::before {
  content: "\200b"; }

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden; }

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5; }

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important; }

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important; }

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  -o-transition: margin-left 0.5s;
  transition: margin-left 0.5s; }

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.bootstrap-switch.bootstrap-switch-focused {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent; }

.ml-auto.navbar-nav {
  background-color: rgba(255, 255, 255, 0.96); }

.navbar {
  border: 0;
  font-size: 16px;
  border-radius: 0;
  height: 76px;
  box-shadow: 9px 1px 15px rgba(69, 65, 78, 0.1);
  background-color: rgba(255, 255, 255, 0.96);
  margin: 0;
  padding: 0; }
  .navbar .ml-auto {
    padding-right: 25px; }
  .navbar .navbar-brand {
    font-weight: normal;
    margin: 29px auto 29px 25px !important;
    padding: 0px;
    font-size: 13px;
    line-height: 18px;
    height: 18px;
    color: #878a99 !important; }
    .navbar .navbar-brand a {
      color: #878a99 !important;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px; }
  .navbar .navbar-nav > a {
    padding: 0px 22px 0px 22px;
    font-weight: normal;
    font-size: 13px;
    color: #878a99 !important; }
  .navbar .navbar-nav > a[id="timer"] {
    width: 235px; }
  .navbar .navbar-nav > a[id="profile"] {
    border-left: 1px solid rgba(160, 168, 197, 0.3);
    border-right: 1px solid rgba(160, 168, 197, 0.3);
    padding: 0 22px;
    height: 20px; }
    .navbar .navbar-nav > a[id="profile"] i {
      font-size: 28px;
      margin-top: -4px;
      margin-right: 2px; }
    .navbar .navbar-nav > a[id="profile"] span {
      vertical-align: top;
      margin-top: 2px; }
  .navbar .navbar-nav > a[id="docs"] {
    border-right: 1px solid rgba(160, 168, 197, 0.3);
    padding: 0 22px; }
    .navbar .navbar-nav > a[id="docs"] i {
      font-size: 26px;
      margin-top: -3px; }
    .navbar .navbar-nav > a[id="docs"] span {
      display: none; }
  .navbar .navbar-nav > a[id="logout"] {
    padding: 0 22px;
    padding-right: 0px; }
    .navbar .navbar-nav > a[id="logout"] i {
      font-size: 26px;
      margin-top: -3px; }
    .navbar .navbar-nav > a[id="logout"] span {
      display: none; }
  .navbar .navbar-nav > a.btn {
    margin: 15px 3px;
    padding: 8px 16px; }
  .navbar .navbar-nav > a.btn-round {
    margin: 16px 3px; }
  .navbar .navbar-nav > a [class^="fa"] {
    font-size: 19px;
    position: relative;
    line-height: 16px;
    top: 1px; }
  @media (max-width: 991px) {
    .navbar .navbar-nav > a {
      margin: 8px 0;
      width: 100%; }
    .navbar .navbar-nav > a[id="timer"] {
      width: 100%; }
    .navbar .navbar-nav > a[id="profile"] {
      border-left: none;
      border-right: none; }
    .navbar .navbar-nav > a[id="docs"] {
      border-right: none; }
      .navbar .navbar-nav > a[id="docs"] span {
        display: inline;
        vertical-align: top;
        margin-top: 2px;
        margin-left: 6px; }
    .navbar .navbar-nav > a[id="logout"] span {
      display: inline;
      vertical-align: top;
      margin-top: 2px;
      margin-left: 6px; }
    .navbar .navbar-nav > a.btn {
      margin: 15px 3px;
      padding: 8px 16px; }
    .navbar .navbar-nav > a.btn-round {
      margin: 16px 3px; }
    .navbar .navbar-nav > a [class^="fa"] {
      font-size: 19px;
      position: relative;
      line-height: 16px;
      top: 1px; } }
  .navbar .btn {
    margin: 15px 3px;
    font-size: 14px; }
  .navbar.fixed {
    width: calc(100% - calc(100% - 260px));
    right: 0;
    left: auto;
    border-radius: 0; }

.navbar-toggler {
  margin-right: 25px; }

.footer {
  background-color: #ffffff;
  line-height: 20px;
  height: 57px; }
  .footer nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal; }
    .footer nav > ul a:not(.btn) {
      color: #9a9a9a;
      display: block;
      margin-bottom: 3px; }
      .footer nav > ul a:not(.btn):hover, .footer nav > ul a:not(.btn):focus {
        color: #777777; }
  .footer .social-area {
    padding: 15px 0; }
    .footer .social-area h5 {
      padding-bottom: 15px; }
  .footer .social-area > a:not(.btn) {
    color: #9a9a9a;
    display: inline-block;
    vertical-align: top;
    padding: 10px 5px;
    font-size: 20px;
    font-weight: normal;
    line-height: 20px;
    text-align: center; }
    .footer .social-area > a:not(.btn):hover, .footer .social-area > a:not(.btn):focus {
      color: #878a99; }
  .footer .copyright {
    color: #878a99;
    margin: 19px 29px 19px 0px;
    line-height: 18px;
    font-size: 13px; }
  .footer hr {
    border-color: #dddddd; }
  .footer .title {
    color: #878a99; }

.footer-default {
  background-color: #f5f5f5; }

.footer:not(.footer-big) nav > ul {
  font-size: 14px; }
  .footer:not(.footer-big) nav > ul li {
    margin-left: 20px;
    float: left; }
  .footer:not(.footer-big) nav > ul a {
    padding: 10px 0px;
    margin: 10px 10px 10px 0px; }

.login-page {
  width: 100%;
  height: 100%;
  background: #f9f9fc;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  overflow: auto; }
  .login-page .login-container {
    height: 560px;
    background-color: #ffffff;
    border-radius: 4px;
    z-index: 1;
    display: grid;
    grid-template-columns: 366px 541px;
    box-shadow: 2px 2px 11px rgba(69, 65, 78, 0.06); }
    .login-page .login-container .logo {
      margin: 40px 0 0 40px; }
    .login-page .login-container .form {
      width: 100%;
      height: 100%;
      display: flex;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center; }
      .login-page .login-container .form form {
        width: 301px;
        padding: 0; }
        .login-page .login-container .form form .header-login {
          text-align: left;
          margin-bottom: 24px; }
        .login-page .login-container .form form input {
          padding: 12px 30px 12px 30px; }
  .login-page .forgot .header-login {
    margin: 0 0 10px; }
  .login-page .forgot .inputs-forgot {
    display: grid;
    grid-template-columns: 144px 242px;
    margin: 0px 32px; }
    .login-page .forgot .inputs-forgot .forgot-select {
      width: 133px; }
      .login-page .forgot .inputs-forgot .forgot-select .select__control {
        color: #5d6072;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        border: 1px solid #f3f4f6;
        box-sizing: border-box;
        border-radius: 4px; }
  .login-page .forgot .captcha {
    margin-bottom: 36px; }
  .login-page .forgot input {
    padding: 12px 12px 12px 12px;
    height: 40px; }
  .login-page .forgot #phone-form-control {
    padding: 12px 12px 12px 60px; }
  .login-page .forgot #react-select-2-input {
    height: 38px; }
  .login-page .expired .header-login {
    color: #f06292; }
  .login-page .expired .tooltip-text {
    margin: 0px 45px 8px 45px; }
  .login-page .expired .button-submit {
    margin-top: 20px; }
  .login-page .expired .login-page-eye {
    margin-top: -45px; }
  .login-page .first-time .header-login {
    color: #78c350; }
  .login-page .first-time .tooltip-text {
    margin: 0px 45px 8px 45px; }
  .login-page .first-time .button-submit {
    margin-top: 20px; }
  .login-page .first-time .login-page-eye {
    margin-top: -45px; }
  .login-page .login-card {
    background: #ffffff;
    width: 540px;
    box-shadow: 2px 2px 11px rgba(69, 65, 78, 0.06);
    border-radius: 4px;
    padding: 45px; }
  .login-page .header-login {
    text-transform: uppercase;
    color: #00b185;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    text-align: center; }
  .login-page .tooltip-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #5d6072;
    margin: 0px 30px 24px 30px; }
  .login-page .form-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .login-page .form-container .form {
      width: 300px; }
  .login-page .input-container {
    margin: 16px 0; }
  .login-page input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e8ecef;
    padding: 12px 30px 12px 12px;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px; }
  .login-page input::placeholder {
    color: #acafbc; }
  .login-page .login-page-eye {
    color: #acafbc;
    font-size: 20px;
    width: 20px;
    position: relative;
    float: right;
    margin-top: -30px;
    margin-right: 8px;
    z-index: 2;
    cursor: pointer; }
  .login-page .icon {
    position: absolute;
    color: #acafbc;
    font-size: 20px;
    width: 20px;
    margin: 10px auto 10px 5px;
    text-align: center; }
  .login-page .button-submit {
    height: 40px;
    width: 100%;
    color: #ffffff;
    background: #00b185;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    border: none;
    opacity: 1; }
  .login-page .loading {
    background: #ffffff;
    border: none; }
  .login-page .link {
    color: #00b185;
    text-decoration-line: underline;
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    float: right;
    margin-top: -8px;
    margin-bottom: 13px; }

.otp-status {
  font-size: 13px;
  color: #78c350;
  font-weight: 400;
  line-height: 16px;
  text-align: center; }

.access-denied {
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  color: #f06292; }

@media (max-width: 991px) {
  .login-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important; }
    .login-container .login-image {
      display: none; } }

.success {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 94%; }

.success:focus {
  background-color: #ffffff;
  border: 1px solid #87cb16;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333; }

.wrong {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 94%; }

.wrong:focus {
  background-color: #ffffff;
  border: 1px solid #ff4a55;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333; }

.form-control {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control:focus {
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333; }

.has-success .form-control,
.has-error .form-control,
.has-success .form-control:focus,
.has-error .form-control:focus {
  border-color: #e3e3e3;
  -webkit-box-shadow: none;
  box-shadow: none; }

.has-success .form-control {
  color: black; }

.has-success .form-control:focus {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  border-color: #87cb16; }

.has-error .form-control {
  color: black; }

.has-error .form-control:focus {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  border-color: #ff4a55; }

.form-control + .form-control-feedback {
  border-radius: 6px;
  font-size: 14px;
  margin-top: -7px;
  position: absolute;
  right: 10px;
  top: 50%;
  vertical-align: middle; }

.open .form-control {
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent; }

.eye {
  position: relative;
  border: none !important;
  background: none !important;
  height: 14px;
  width: 10px;
  right: 60px;
  top: 5px; }

.success {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 94%; }

.success:focus {
  background-color: #ffffff;
  border: 1px solid #87cb16;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333; }

.wrong {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 94%; }

.wrong:focus {
  background-color: #ffffff;
  border: 1px solid #ff4a55;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333; }

.header1 {
  font-size: 20px; }

.header2 {
  font-size: 18px;
  text-align: center; }

.column {
  font-size: 15px;
  padding-left: 100px; }

.labelHeader {
  margin-top: 40px;
  text-align: center; }

.right {
  text-align: right; }

.information {
  text-align: center;
  color: blue;
  font-size: 20px; }

.shop-header {
  display: inline;
  margin: 10px 0 5px 15px; }

.admin-dashboard .card-large, .admin-dashboard .card-map {
  height: auto;
  min-height: 20vmax; }
  .admin-dashboard .card-large > .card, .admin-dashboard .card-map > .card, .admin-dashboard .card-large > .card-small, .admin-dashboard .card-map > .card-small, .admin-dashboard .card-large > .card-large, .admin-dashboard .card-map > .card-large, .admin-dashboard .card-large > .card-map, .admin-dashboard .card-map > .card-map, .admin-dashboard .card-large > .card-for-basic-report, .admin-dashboard .card-map > .card-for-basic-report, .admin-dashboard .card-large > .card-large-merchant, .admin-dashboard .card-map > .card-large-merchant, .admin-dashboard .card-large > .card-large-group, .admin-dashboard .card-map > .card-large-group, .admin-dashboard .card-large > .card-large-report, .admin-dashboard .card-map > .card-large-report, .admin-dashboard .card-large > .card-detail, .admin-dashboard .card-map > .card-detail {
    margin: 0;
    padding: 0px; }
    .admin-dashboard .card-large > .card div, .admin-dashboard .card-map > .card div, .admin-dashboard .card-large > .card-small div, .admin-dashboard .card-map > .card-small div, .admin-dashboard .card-large > .card-large div, .admin-dashboard .card-map > .card-large div, .admin-dashboard .card-large > .card-map div, .admin-dashboard .card-map > .card-map div, .admin-dashboard .card-large > .card-for-basic-report div, .admin-dashboard .card-map > .card-for-basic-report div, .admin-dashboard .card-large > .card-large-merchant div, .admin-dashboard .card-map > .card-large-merchant div, .admin-dashboard .card-large > .card-large-group div, .admin-dashboard .card-map > .card-large-group div, .admin-dashboard .card-large > .card-large-report div, .admin-dashboard .card-map > .card-large-report div, .admin-dashboard .card-large > .card-detail div, .admin-dashboard .card-map > .card-detail div {
      padding: 10px 15px 0 15px;
      border: 1px solid #f9f9fc; }
  .admin-dashboard .card-large p, .admin-dashboard .card-map p {
    color: #9a9a9a; }
  .admin-dashboard .card-large > .chart-title-select, .admin-dashboard .card-map > .chart-title-select {
    display: flex; }
    .admin-dashboard .card-large > .chart-title-select > div, .admin-dashboard .card-map > .chart-title-select > div {
      flex: 1 0 auto; }
  .admin-dashboard .card-large > .chart, .admin-dashboard .card-map > .chart {
    margin-top: 15px; }
  .admin-dashboard .card-large .loader, .admin-dashboard .card-map .loader {
    margin: 15% 0; }

.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%; }
  .react-tel-input :disabled {
    cursor: not-allowed; }
  .react-tel-input .flag {
    width: 25px;
    height: 20px;
    background: url("../img/high-res.png");
    background-repeat: no-repeat; }
  .react-tel-input .ad {
    background-position: -48px -24px; }
  .react-tel-input .ae {
    background-position: -72px -24px; }
  .react-tel-input .af {
    background-position: -96px -24px; }
  .react-tel-input .ag {
    background-position: -120px -24px; }
  .react-tel-input .ai {
    background-position: -144px -24px; }
  .react-tel-input .al {
    background-position: -168px -24px; }
  .react-tel-input .am {
    background-position: -192px -24px; }
  .react-tel-input .an {
    background-position: -216px -24px; }
  .react-tel-input .ao {
    background-position: -240px -24px; }
  .react-tel-input .aq {
    background-position: -264px -24px; }
  .react-tel-input .ar {
    background-position: -288px -24px; }
  .react-tel-input .as {
    background-position: -312px -24px; }
  .react-tel-input .at {
    background-position: -336px -24px; }
  .react-tel-input .au {
    background-position: -360px -24px; }
  .react-tel-input .aw {
    background-position: -384px -24px; }
  .react-tel-input .ax {
    background-position: -0px -48px; }
  .react-tel-input .az {
    background-position: -24px -48px; }
  .react-tel-input .ba {
    background-position: -48px -48px; }
  .react-tel-input .bb {
    background-position: -72px -48px; }
  .react-tel-input .bd {
    background-position: -96px -48px; }
  .react-tel-input .be {
    background-position: -120px -48px; }
  .react-tel-input .bf {
    background-position: -144px -48px; }
  .react-tel-input .bg {
    background-position: -168px -48px; }
  .react-tel-input .bh {
    background-position: -192px -48px; }
  .react-tel-input .bi {
    background-position: -216px -48px; }
  .react-tel-input .bj {
    background-position: -240px -48px; }
  .react-tel-input .bl {
    background-position: -264px -48px; }
  .react-tel-input .bm {
    background-position: -288px -48px; }
  .react-tel-input .bn {
    background-position: -312px -48px; }
  .react-tel-input .bo {
    background-position: -336px -48px; }
  .react-tel-input .br {
    background-position: -360px -48px; }
  .react-tel-input .bs {
    background-position: -384px -48px; }
  .react-tel-input .bt {
    background-position: -0px -72px; }
  .react-tel-input .bw {
    background-position: -24px -72px; }
  .react-tel-input .by {
    background-position: -48px -72px; }
  .react-tel-input .bz {
    background-position: -72px -72px; }
  .react-tel-input .ca {
    background-position: -96px -72px; }
  .react-tel-input .cc {
    background-position: -120px -72px; }
  .react-tel-input .cd {
    background-position: -144px -72px; }
  .react-tel-input .cf {
    background-position: -168px -72px; }
  .react-tel-input .cg {
    background-position: -192px -72px; }
  .react-tel-input .ch {
    background-position: -216px -72px; }
  .react-tel-input .ci {
    background-position: -240px -72px; }
  .react-tel-input .ck {
    background-position: -264px -72px; }
  .react-tel-input .cl {
    background-position: -288px -72px; }
  .react-tel-input .cm {
    background-position: -312px -72px; }
  .react-tel-input .cn {
    background-position: -336px -72px; }
  .react-tel-input .co {
    background-position: -360px -72px; }
  .react-tel-input .cr {
    background-position: -384px -72px; }
  .react-tel-input .cu {
    background-position: -0px -96px; }
  .react-tel-input .cv {
    background-position: -24px -96px; }
  .react-tel-input .cw {
    background-position: -48px -96px; }
  .react-tel-input .cx {
    background-position: -72px -96px; }
  .react-tel-input .cy {
    background-position: -96px -96px; }
  .react-tel-input .cz {
    background-position: -120px -96px; }
  .react-tel-input .de {
    background-position: -144px -96px; }
  .react-tel-input .dj {
    background-position: -168px -96px; }
  .react-tel-input .dk {
    background-position: -192px -96px; }
  .react-tel-input .dm {
    background-position: -216px -96px; }
  .react-tel-input .do {
    background-position: -240px -96px; }
  .react-tel-input .dz {
    background-position: -264px -96px; }
  .react-tel-input .ec {
    background-position: -288px -96px; }
  .react-tel-input .ee {
    background-position: -312px -96px; }
  .react-tel-input .eg {
    background-position: -336px -96px; }
  .react-tel-input .eh {
    background-position: -360px -96px; }
  .react-tel-input .er {
    background-position: -384px -96px; }
  .react-tel-input .es {
    background-position: -0px -120px; }
  .react-tel-input .et {
    background-position: -24px -120px; }
  .react-tel-input .eu {
    background-position: -48px -120px; }
  .react-tel-input .fi {
    background-position: -72px -120px; }
  .react-tel-input .fj {
    background-position: -96px -120px; }
  .react-tel-input .fk {
    background-position: -120px -120px; }
  .react-tel-input .fm {
    background-position: -144px -120px; }
  .react-tel-input .fo {
    background-position: -168px -120px; }
  .react-tel-input .fr {
    background-position: -192px -120px; }
  .react-tel-input .ga {
    background-position: -216px -120px; }
  .react-tel-input .gb {
    background-position: -240px -120px; }
  .react-tel-input .gd {
    background-position: -264px -120px; }
  .react-tel-input .ge {
    background-position: -288px -120px; }
  .react-tel-input .gg {
    background-position: -312px -120px; }
  .react-tel-input .gh {
    background-position: -336px -120px; }
  .react-tel-input .gi {
    background-position: -360px -120px; }
  .react-tel-input .gl {
    background-position: -384px -120px; }
  .react-tel-input .gm {
    background-position: -0px -144px; }
  .react-tel-input .gn {
    background-position: -24px -144px; }
  .react-tel-input .gq {
    background-position: -48px -144px; }
  .react-tel-input .gr {
    background-position: -72px -144px; }
  .react-tel-input .gs {
    background-position: -96px -144px; }
  .react-tel-input .gt {
    background-position: -120px -144px; }
  .react-tel-input .gu {
    background-position: -144px -144px; }
  .react-tel-input .gw {
    background-position: -168px -144px; }
  .react-tel-input .gy {
    background-position: -192px -144px; }
  .react-tel-input .hk {
    background-position: -216px -144px; }
  .react-tel-input .hn {
    background-position: -240px -144px; }
  .react-tel-input .hr {
    background-position: -264px -144px; }
  .react-tel-input .ht {
    background-position: -288px -144px; }
  .react-tel-input .hu {
    background-position: -312px -144px; }
  .react-tel-input .ic {
    background-position: -336px -144px; }
  .react-tel-input .id {
    background-position: -360px -144px; }
  .react-tel-input .ie {
    background-position: -384px -144px; }
  .react-tel-input .il {
    background-position: -0px -168px; }
  .react-tel-input .im {
    background-position: -24px -168px; }
  .react-tel-input .in {
    background-position: -48px -168px; }
  .react-tel-input .iq {
    background-position: -72px -168px; }
  .react-tel-input .ir {
    background-position: -96px -168px; }
  .react-tel-input .is {
    background-position: -120px -168px; }
  .react-tel-input .it {
    background-position: -144px -168px; }
  .react-tel-input .je {
    background-position: -168px -168px; }
  .react-tel-input .jm {
    background-position: -192px -168px; }
  .react-tel-input .jo {
    background-position: -216px -168px; }
  .react-tel-input .jp {
    background-position: -240px -168px; }
  .react-tel-input .ke {
    background-position: -264px -168px; }
  .react-tel-input .kg {
    background-position: -288px -168px; }
  .react-tel-input .kh {
    background-position: -312px -168px; }
  .react-tel-input .ki {
    background-position: -336px -168px; }
  .react-tel-input .km {
    background-position: -360px -168px; }
  .react-tel-input .kn {
    background-position: -384px -168px; }
  .react-tel-input .kp {
    background-position: -0px -192px; }
  .react-tel-input .kr {
    background-position: -24px -192px; }
  .react-tel-input .kw {
    background-position: -48px -192px; }
  .react-tel-input .ky {
    background-position: -72px -192px; }
  .react-tel-input .kz {
    background-position: -96px -192px; }
  .react-tel-input .la {
    background-position: -120px -192px; }
  .react-tel-input .lb {
    background-position: -144px -192px; }
  .react-tel-input .lc {
    background-position: -168px -192px; }
  .react-tel-input .li {
    background-position: -192px -192px; }
  .react-tel-input .lk {
    background-position: -216px -192px; }
  .react-tel-input .lr {
    background-position: -240px -192px; }
  .react-tel-input .ls {
    background-position: -264px -192px; }
  .react-tel-input .lt {
    background-position: -288px -192px; }
  .react-tel-input .lu {
    background-position: -312px -192px; }
  .react-tel-input .lv {
    background-position: -336px -192px; }
  .react-tel-input .ly {
    background-position: -360px -192px; }
  .react-tel-input .ma {
    background-position: -384px -192px; }
  .react-tel-input .mc {
    background-position: -0px -216px; }
  .react-tel-input .md {
    background-position: -24px -216px; }
  .react-tel-input .me {
    background-position: -48px -216px; }
  .react-tel-input .mf {
    background-position: -72px -216px; }
  .react-tel-input .mg {
    background-position: -96px -216px; }
  .react-tel-input .mh {
    background-position: -120px -216px; }
  .react-tel-input .mk {
    background-position: -144px -216px; }
  .react-tel-input .ml {
    background-position: -168px -216px; }
  .react-tel-input .mm {
    background-position: -192px -216px; }
  .react-tel-input .mn {
    background-position: -216px -216px; }
  .react-tel-input .mo {
    background-position: -240px -216px; }
  .react-tel-input .mp {
    background-position: -264px -216px; }
  .react-tel-input .mq {
    background-position: -288px -216px; }
  .react-tel-input .mr {
    background-position: -312px -216px; }
  .react-tel-input .ms {
    background-position: -336px -216px; }
  .react-tel-input .mt {
    background-position: -360px -216px; }
  .react-tel-input .mu {
    background-position: -384px -216px; }
  .react-tel-input .mv {
    background-position: -0px -240px; }
  .react-tel-input .mw {
    background-position: -24px -240px; }
  .react-tel-input .mx {
    background-position: -48px -240px; }
  .react-tel-input .my {
    background-position: -72px -240px; }
  .react-tel-input .mz {
    background-position: -96px -240px; }
  .react-tel-input .na {
    background-position: -120px -240px; }
  .react-tel-input .nc {
    background-position: -144px -240px; }
  .react-tel-input .ne {
    background-position: -168px -240px; }
  .react-tel-input .nf {
    background-position: -192px -240px; }
  .react-tel-input .ng {
    background-position: -216px -240px; }
  .react-tel-input .ni {
    background-position: -240px -240px; }
  .react-tel-input .nl {
    background-position: -264px -240px; }
  .react-tel-input .no {
    background-position: -288px -240px; }
  .react-tel-input .np {
    background-position: -312px -240px; }
  .react-tel-input .nr {
    background-position: -336px -240px; }
  .react-tel-input .nu {
    background-position: -360px -240px; }
  .react-tel-input .nz {
    background-position: -384px -240px; }
  .react-tel-input .om {
    background-position: -0px -264px; }
  .react-tel-input .pa {
    background-position: -24px -264px; }
  .react-tel-input .pe {
    background-position: -48px -264px; }
  .react-tel-input .pf {
    background-position: -72px -264px; }
  .react-tel-input .pg {
    background-position: -96px -264px; }
  .react-tel-input .ph {
    background-position: -120px -264px; }
  .react-tel-input .pk {
    background-position: -192px -264px; }
  .react-tel-input .pl {
    background-position: -216px -264px; }
  .react-tel-input .pn {
    background-position: -240px -264px; }
  .react-tel-input .pr {
    background-position: -264px -264px; }
  .react-tel-input .ps {
    background-position: -288px -264px; }
  .react-tel-input .pt {
    background-position: -312px -264px; }
  .react-tel-input .pw {
    background-position: -336px -264px; }
  .react-tel-input .py {
    background-position: -360px -264px; }
  .react-tel-input .qa {
    background-position: -384px -264px; }
  .react-tel-input .ro {
    background-position: -0px -288px; }
  .react-tel-input .rs {
    background-position: -24px -288px; }
  .react-tel-input .ru {
    background-position: -48px -288px; }
  .react-tel-input .rw {
    background-position: -72px -288px; }
  .react-tel-input .sa {
    background-position: -96px -288px; }
  .react-tel-input .sb {
    background-position: -120px -288px; }
  .react-tel-input .sc {
    background-position: -144px -288px; }
  .react-tel-input .sd {
    background-position: -168px -288px; }
  .react-tel-input .se {
    background-position: -192px -288px; }
  .react-tel-input .sg {
    background-position: -216px -288px; }
  .react-tel-input .sh {
    background-position: -240px -288px; }
  .react-tel-input .si {
    background-position: -264px -288px; }
  .react-tel-input .sk {
    background-position: -288px -288px; }
  .react-tel-input .sl {
    background-position: -312px -288px; }
  .react-tel-input .sm {
    background-position: -336px -288px; }
  .react-tel-input .sn {
    background-position: -360px -288px; }
  .react-tel-input .so {
    background-position: -384px -288px; }
  .react-tel-input .sr {
    background-position: -0px -312px; }
  .react-tel-input .ss {
    background-position: -24px -312px; }
  .react-tel-input .st {
    background-position: -48px -312px; }
  .react-tel-input .sv {
    background-position: -72px -312px; }
  .react-tel-input .sy {
    background-position: -96px -312px; }
  .react-tel-input .sz {
    background-position: -120px -312px; }
  .react-tel-input .tc {
    background-position: -144px -312px; }
  .react-tel-input .td {
    background-position: -168px -312px; }
  .react-tel-input .tf {
    background-position: -192px -312px; }
  .react-tel-input .tg {
    background-position: -216px -312px; }
  .react-tel-input .th {
    background-position: -240px -312px; }
  .react-tel-input .tj {
    background-position: -264px -312px; }
  .react-tel-input .tk {
    background-position: -288px -312px; }
  .react-tel-input .tl {
    background-position: -312px -312px; }
  .react-tel-input .tm {
    background-position: -336px -312px; }
  .react-tel-input .tn {
    background-position: -360px -312px; }
  .react-tel-input .to {
    background-position: -384px -312px; }
  .react-tel-input .tr {
    background-position: -0px -336px; }
  .react-tel-input .tt {
    background-position: -24px -336px; }
  .react-tel-input .tv {
    background-position: -48px -336px; }
  .react-tel-input .tw {
    background-position: -72px -336px; }
  .react-tel-input .tz {
    background-position: -96px -336px; }
  .react-tel-input .ua {
    background-position: -120px -336px; }
  .react-tel-input .ug {
    background-position: -144px -336px; }
  .react-tel-input .us {
    background-position: -168px -336px; }
  .react-tel-input .uy {
    background-position: -192px -336px; }
  .react-tel-input .uz {
    background-position: -216px -336px; }
  .react-tel-input .va {
    background-position: -240px -336px; }
  .react-tel-input .vc {
    background-position: -264px -336px; }
  .react-tel-input .ve {
    background-position: -288px -336px; }
  .react-tel-input .vg {
    background-position: -312px -336px; }
  .react-tel-input .vi {
    background-position: -336px -336px; }
  .react-tel-input .vn {
    background-position: -360px -336px; }
  .react-tel-input .vu {
    background-position: -384px -336px; }
  .react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .react-tel-input .hide {
    display: none; }
  .react-tel-input .v-hide {
    visibility: hidden; }

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 60px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none; }
  .react-tel-input .form-control.invalid-number {
    border: 1px solid #d79f9f;
    background-color: #FAF0F0;
    border-left-color: #cacaca; }
    .react-tel-input .form-control.invalid-number:focus {
      border: 1px solid #d79f9f;
      border-left-color: #cacaca;
      background-color: #FAF0F0; }
  .react-tel-input .form-control.open {
    z-index: 2; }

.react-tel-input .flag-dropdown {
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px; }
  .react-tel-input .flag-dropdown.open {
    z-index: 2;
    background: #fff;
    border-radius: 3px 0 0 0; }
    .react-tel-input .flag-dropdown.open .selected-flag {
      background: #fff;
      border-radius: 3px 0 0 0; }
  .react-tel-input .flag-dropdown:hover, .react-tel-input .flag-dropdown:focus {
    cursor: pointer; }
    .react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag {
      background-color: #fff; }

.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default; }
  .react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
    background-color: transparent; }

.react-tel-input .selected-flag {
  position: relative;
  width: 49px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px; }
  .react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -12px; }
  .react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -1px;
    left: 30px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555; }
    .react-tel-input .selected-flag .arrow.up {
      border-top: none;
      border-bottom: 4px solid #555; }

.react-tel-input .country-list {
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: 300px;
  max-height: 224px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px; }
  .react-tel-input .country-list .flag {
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 3px; }
  .react-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc; }
  .react-tel-input .country-list .country {
    padding: 7px 9px 7px 44px;
    position: relative; }
    .react-tel-input .country-list .country .dial-code {
      color: #6b6b6b; }
    .react-tel-input .country-list .country:hover {
      background-color: #f1f1f1; }
    .react-tel-input .country-list .country.highlight {
      background-color: #f1f1f1; }
  .react-tel-input .country-list .country-name {
    margin-right: 6px; }
  .react-tel-input .country-list .search {
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px 0 6px 10px; }
  .react-tel-input .country-list .search-emoji {
    font-size: 15px; }
  .react-tel-input .country-list .search-box {
    border: 1px solid #cacaca;
    border-radius: 3px;
    font-size: 15px;
    line-height: 15px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none; }
  .react-tel-input .country-list .no-entries-message {
    padding: 7px 10px 11px;
    opacity: .7; }

.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%; }
  .react-tel-input :disabled {
    cursor: not-allowed; }
  .react-tel-input .flag {
    width: 25px;
    height: 20px;
    background: url("../img/high-res.png");
    background-repeat: no-repeat; }
  .react-tel-input .ad {
    background-position: -48px -24px; }
  .react-tel-input .ae {
    background-position: -72px -24px; }
  .react-tel-input .af {
    background-position: -96px -24px; }
  .react-tel-input .ag {
    background-position: -120px -24px; }
  .react-tel-input .ai {
    background-position: -144px -24px; }
  .react-tel-input .al {
    background-position: -168px -24px; }
  .react-tel-input .am {
    background-position: -192px -24px; }
  .react-tel-input .an {
    background-position: -216px -24px; }
  .react-tel-input .ao {
    background-position: -240px -24px; }
  .react-tel-input .aq {
    background-position: -264px -24px; }
  .react-tel-input .ar {
    background-position: -288px -24px; }
  .react-tel-input .as {
    background-position: -312px -24px; }
  .react-tel-input .at {
    background-position: -336px -24px; }
  .react-tel-input .au {
    background-position: -360px -24px; }
  .react-tel-input .aw {
    background-position: -384px -24px; }
  .react-tel-input .ax {
    background-position: -0px -48px; }
  .react-tel-input .az {
    background-position: -24px -48px; }
  .react-tel-input .ba {
    background-position: -48px -48px; }
  .react-tel-input .bb {
    background-position: -72px -48px; }
  .react-tel-input .bd {
    background-position: -96px -48px; }
  .react-tel-input .be {
    background-position: -120px -48px; }
  .react-tel-input .bf {
    background-position: -144px -48px; }
  .react-tel-input .bg {
    background-position: -168px -48px; }
  .react-tel-input .bh {
    background-position: -192px -48px; }
  .react-tel-input .bi {
    background-position: -216px -48px; }
  .react-tel-input .bj {
    background-position: -240px -48px; }
  .react-tel-input .bl {
    background-position: -264px -48px; }
  .react-tel-input .bm {
    background-position: -288px -48px; }
  .react-tel-input .bn {
    background-position: -312px -48px; }
  .react-tel-input .bo {
    background-position: -336px -48px; }
  .react-tel-input .br {
    background-position: -360px -48px; }
  .react-tel-input .bs {
    background-position: -384px -48px; }
  .react-tel-input .bt {
    background-position: -0px -72px; }
  .react-tel-input .bw {
    background-position: -24px -72px; }
  .react-tel-input .by {
    background-position: -48px -72px; }
  .react-tel-input .bz {
    background-position: -72px -72px; }
  .react-tel-input .ca {
    background-position: -96px -72px; }
  .react-tel-input .cc {
    background-position: -120px -72px; }
  .react-tel-input .cd {
    background-position: -144px -72px; }
  .react-tel-input .cf {
    background-position: -168px -72px; }
  .react-tel-input .cg {
    background-position: -192px -72px; }
  .react-tel-input .ch {
    background-position: -216px -72px; }
  .react-tel-input .ci {
    background-position: -240px -72px; }
  .react-tel-input .ck {
    background-position: -264px -72px; }
  .react-tel-input .cl {
    background-position: -288px -72px; }
  .react-tel-input .cm {
    background-position: -312px -72px; }
  .react-tel-input .cn {
    background-position: -336px -72px; }
  .react-tel-input .co {
    background-position: -360px -72px; }
  .react-tel-input .cr {
    background-position: -384px -72px; }
  .react-tel-input .cu {
    background-position: -0px -96px; }
  .react-tel-input .cv {
    background-position: -24px -96px; }
  .react-tel-input .cw {
    background-position: -48px -96px; }
  .react-tel-input .cx {
    background-position: -72px -96px; }
  .react-tel-input .cy {
    background-position: -96px -96px; }
  .react-tel-input .cz {
    background-position: -120px -96px; }
  .react-tel-input .de {
    background-position: -144px -96px; }
  .react-tel-input .dj {
    background-position: -168px -96px; }
  .react-tel-input .dk {
    background-position: -192px -96px; }
  .react-tel-input .dm {
    background-position: -216px -96px; }
  .react-tel-input .do {
    background-position: -240px -96px; }
  .react-tel-input .dz {
    background-position: -264px -96px; }
  .react-tel-input .ec {
    background-position: -288px -96px; }
  .react-tel-input .ee {
    background-position: -312px -96px; }
  .react-tel-input .eg {
    background-position: -336px -96px; }
  .react-tel-input .eh {
    background-position: -360px -96px; }
  .react-tel-input .er {
    background-position: -384px -96px; }
  .react-tel-input .es {
    background-position: -0px -120px; }
  .react-tel-input .et {
    background-position: -24px -120px; }
  .react-tel-input .eu {
    background-position: -48px -120px; }
  .react-tel-input .fi {
    background-position: -72px -120px; }
  .react-tel-input .fj {
    background-position: -96px -120px; }
  .react-tel-input .fk {
    background-position: -120px -120px; }
  .react-tel-input .fm {
    background-position: -144px -120px; }
  .react-tel-input .fo {
    background-position: -168px -120px; }
  .react-tel-input .fr {
    background-position: -192px -120px; }
  .react-tel-input .ga {
    background-position: -216px -120px; }
  .react-tel-input .gb {
    background-position: -240px -120px; }
  .react-tel-input .gd {
    background-position: -264px -120px; }
  .react-tel-input .ge {
    background-position: -288px -120px; }
  .react-tel-input .gg {
    background-position: -312px -120px; }
  .react-tel-input .gh {
    background-position: -336px -120px; }
  .react-tel-input .gi {
    background-position: -360px -120px; }
  .react-tel-input .gl {
    background-position: -384px -120px; }
  .react-tel-input .gm {
    background-position: -0px -144px; }
  .react-tel-input .gn {
    background-position: -24px -144px; }
  .react-tel-input .gq {
    background-position: -48px -144px; }
  .react-tel-input .gr {
    background-position: -72px -144px; }
  .react-tel-input .gs {
    background-position: -96px -144px; }
  .react-tel-input .gt {
    background-position: -120px -144px; }
  .react-tel-input .gu {
    background-position: -144px -144px; }
  .react-tel-input .gw {
    background-position: -168px -144px; }
  .react-tel-input .gy {
    background-position: -192px -144px; }
  .react-tel-input .hk {
    background-position: -216px -144px; }
  .react-tel-input .hn {
    background-position: -240px -144px; }
  .react-tel-input .hr {
    background-position: -264px -144px; }
  .react-tel-input .ht {
    background-position: -288px -144px; }
  .react-tel-input .hu {
    background-position: -312px -144px; }
  .react-tel-input .ic {
    background-position: -336px -144px; }
  .react-tel-input .id {
    background-position: -360px -144px; }
  .react-tel-input .ie {
    background-position: -384px -144px; }
  .react-tel-input .il {
    background-position: -0px -168px; }
  .react-tel-input .im {
    background-position: -24px -168px; }
  .react-tel-input .in {
    background-position: -48px -168px; }
  .react-tel-input .iq {
    background-position: -72px -168px; }
  .react-tel-input .ir {
    background-position: -96px -168px; }
  .react-tel-input .is {
    background-position: -120px -168px; }
  .react-tel-input .it {
    background-position: -144px -168px; }
  .react-tel-input .je {
    background-position: -168px -168px; }
  .react-tel-input .jm {
    background-position: -192px -168px; }
  .react-tel-input .jo {
    background-position: -216px -168px; }
  .react-tel-input .jp {
    background-position: -240px -168px; }
  .react-tel-input .ke {
    background-position: -264px -168px; }
  .react-tel-input .kg {
    background-position: -288px -168px; }
  .react-tel-input .kh {
    background-position: -312px -168px; }
  .react-tel-input .ki {
    background-position: -336px -168px; }
  .react-tel-input .km {
    background-position: -360px -168px; }
  .react-tel-input .kn {
    background-position: -384px -168px; }
  .react-tel-input .kp {
    background-position: -0px -192px; }
  .react-tel-input .kr {
    background-position: -24px -192px; }
  .react-tel-input .kw {
    background-position: -48px -192px; }
  .react-tel-input .ky {
    background-position: -72px -192px; }
  .react-tel-input .kz {
    background-position: -96px -192px; }
  .react-tel-input .la {
    background-position: -120px -192px; }
  .react-tel-input .lb {
    background-position: -144px -192px; }
  .react-tel-input .lc {
    background-position: -168px -192px; }
  .react-tel-input .li {
    background-position: -192px -192px; }
  .react-tel-input .lk {
    background-position: -216px -192px; }
  .react-tel-input .lr {
    background-position: -240px -192px; }
  .react-tel-input .ls {
    background-position: -264px -192px; }
  .react-tel-input .lt {
    background-position: -288px -192px; }
  .react-tel-input .lu {
    background-position: -312px -192px; }
  .react-tel-input .lv {
    background-position: -336px -192px; }
  .react-tel-input .ly {
    background-position: -360px -192px; }
  .react-tel-input .ma {
    background-position: -384px -192px; }
  .react-tel-input .mc {
    background-position: -0px -216px; }
  .react-tel-input .md {
    background-position: -24px -216px; }
  .react-tel-input .me {
    background-position: -48px -216px; }
  .react-tel-input .mf {
    background-position: -72px -216px; }
  .react-tel-input .mg {
    background-position: -96px -216px; }
  .react-tel-input .mh {
    background-position: -120px -216px; }
  .react-tel-input .mk {
    background-position: -144px -216px; }
  .react-tel-input .ml {
    background-position: -168px -216px; }
  .react-tel-input .mm {
    background-position: -192px -216px; }
  .react-tel-input .mn {
    background-position: -216px -216px; }
  .react-tel-input .mo {
    background-position: -240px -216px; }
  .react-tel-input .mp {
    background-position: -264px -216px; }
  .react-tel-input .mq {
    background-position: -288px -216px; }
  .react-tel-input .mr {
    background-position: -312px -216px; }
  .react-tel-input .ms {
    background-position: -336px -216px; }
  .react-tel-input .mt {
    background-position: -360px -216px; }
  .react-tel-input .mu {
    background-position: -384px -216px; }
  .react-tel-input .mv {
    background-position: -0px -240px; }
  .react-tel-input .mw {
    background-position: -24px -240px; }
  .react-tel-input .mx {
    background-position: -48px -240px; }
  .react-tel-input .my {
    background-position: -72px -240px; }
  .react-tel-input .mz {
    background-position: -96px -240px; }
  .react-tel-input .na {
    background-position: -120px -240px; }
  .react-tel-input .nc {
    background-position: -144px -240px; }
  .react-tel-input .ne {
    background-position: -168px -240px; }
  .react-tel-input .nf {
    background-position: -192px -240px; }
  .react-tel-input .ng {
    background-position: -216px -240px; }
  .react-tel-input .ni {
    background-position: -240px -240px; }
  .react-tel-input .nl {
    background-position: -264px -240px; }
  .react-tel-input .no {
    background-position: -288px -240px; }
  .react-tel-input .np {
    background-position: -312px -240px; }
  .react-tel-input .nr {
    background-position: -336px -240px; }
  .react-tel-input .nu {
    background-position: -360px -240px; }
  .react-tel-input .nz {
    background-position: -384px -240px; }
  .react-tel-input .om {
    background-position: -0px -264px; }
  .react-tel-input .pa {
    background-position: -24px -264px; }
  .react-tel-input .pe {
    background-position: -48px -264px; }
  .react-tel-input .pf {
    background-position: -72px -264px; }
  .react-tel-input .pg {
    background-position: -96px -264px; }
  .react-tel-input .ph {
    background-position: -120px -264px; }
  .react-tel-input .pk {
    background-position: -192px -264px; }
  .react-tel-input .pl {
    background-position: -216px -264px; }
  .react-tel-input .pn {
    background-position: -240px -264px; }
  .react-tel-input .pr {
    background-position: -264px -264px; }
  .react-tel-input .ps {
    background-position: -288px -264px; }
  .react-tel-input .pt {
    background-position: -312px -264px; }
  .react-tel-input .pw {
    background-position: -336px -264px; }
  .react-tel-input .py {
    background-position: -360px -264px; }
  .react-tel-input .qa {
    background-position: -384px -264px; }
  .react-tel-input .ro {
    background-position: -0px -288px; }
  .react-tel-input .rs {
    background-position: -24px -288px; }
  .react-tel-input .ru {
    background-position: -48px -288px; }
  .react-tel-input .rw {
    background-position: -72px -288px; }
  .react-tel-input .sa {
    background-position: -96px -288px; }
  .react-tel-input .sb {
    background-position: -120px -288px; }
  .react-tel-input .sc {
    background-position: -144px -288px; }
  .react-tel-input .sd {
    background-position: -168px -288px; }
  .react-tel-input .se {
    background-position: -192px -288px; }
  .react-tel-input .sg {
    background-position: -216px -288px; }
  .react-tel-input .sh {
    background-position: -240px -288px; }
  .react-tel-input .si {
    background-position: -264px -288px; }
  .react-tel-input .sk {
    background-position: -288px -288px; }
  .react-tel-input .sl {
    background-position: -312px -288px; }
  .react-tel-input .sm {
    background-position: -336px -288px; }
  .react-tel-input .sn {
    background-position: -360px -288px; }
  .react-tel-input .so {
    background-position: -384px -288px; }
  .react-tel-input .sr {
    background-position: -0px -312px; }
  .react-tel-input .ss {
    background-position: -24px -312px; }
  .react-tel-input .st {
    background-position: -48px -312px; }
  .react-tel-input .sv {
    background-position: -72px -312px; }
  .react-tel-input .sy {
    background-position: -96px -312px; }
  .react-tel-input .sz {
    background-position: -120px -312px; }
  .react-tel-input .tc {
    background-position: -144px -312px; }
  .react-tel-input .td {
    background-position: -168px -312px; }
  .react-tel-input .tf {
    background-position: -192px -312px; }
  .react-tel-input .tg {
    background-position: -216px -312px; }
  .react-tel-input .th {
    background-position: -240px -312px; }
  .react-tel-input .tj {
    background-position: -264px -312px; }
  .react-tel-input .tk {
    background-position: -288px -312px; }
  .react-tel-input .tl {
    background-position: -312px -312px; }
  .react-tel-input .tm {
    background-position: -336px -312px; }
  .react-tel-input .tn {
    background-position: -360px -312px; }
  .react-tel-input .to {
    background-position: -384px -312px; }
  .react-tel-input .tr {
    background-position: -0px -336px; }
  .react-tel-input .tt {
    background-position: -24px -336px; }
  .react-tel-input .tv {
    background-position: -48px -336px; }
  .react-tel-input .tw {
    background-position: -72px -336px; }
  .react-tel-input .tz {
    background-position: -96px -336px; }
  .react-tel-input .ua {
    background-position: -120px -336px; }
  .react-tel-input .ug {
    background-position: -144px -336px; }
  .react-tel-input .us {
    background-position: -168px -336px; }
  .react-tel-input .uy {
    background-position: -192px -336px; }
  .react-tel-input .uz {
    background-position: -216px -336px; }
  .react-tel-input .va {
    background-position: -240px -336px; }
  .react-tel-input .vc {
    background-position: -264px -336px; }
  .react-tel-input .ve {
    background-position: -288px -336px; }
  .react-tel-input .vg {
    background-position: -312px -336px; }
  .react-tel-input .vi {
    background-position: -336px -336px; }
  .react-tel-input .vn {
    background-position: -360px -336px; }
  .react-tel-input .vu {
    background-position: -384px -336px; }
  .react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .react-tel-input .hide {
    display: none; }
  .react-tel-input .v-hide {
    visibility: hidden; }

.react-tel-input {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%; }
  .react-tel-input :disabled {
    cursor: not-allowed; }
  .react-tel-input .flag {
    width: 16px;
    height: 11px;
    background: url("../img/flags.png"); }
  .react-tel-input .ad {
    background-position: -16px 0; }
  .react-tel-input .ae {
    background-position: -32px 0; }
  .react-tel-input .af {
    background-position: -48px 0; }
  .react-tel-input .ag {
    background-position: -64px 0; }
  .react-tel-input .ai {
    background-position: -80px 0; }
  .react-tel-input .al {
    background-position: -96px 0; }
  .react-tel-input .am {
    background-position: -112px 0; }
  .react-tel-input .ao {
    background-position: -128px 0; }
  .react-tel-input .ar {
    background-position: -144px 0; }
  .react-tel-input .as {
    background-position: -160px 0; }
  .react-tel-input .at {
    background-position: -176px 0; }
  .react-tel-input .au {
    background-position: -192px 0; }
  .react-tel-input .aw {
    background-position: -208px 0; }
  .react-tel-input .az {
    background-position: -224px 0; }
  .react-tel-input .ba {
    background-position: -240px 0; }
  .react-tel-input .bb {
    background-position: 0 -11px; }
  .react-tel-input .bd {
    background-position: -16px -11px; }
  .react-tel-input .be {
    background-position: -32px -11px; }
  .react-tel-input .bf {
    background-position: -48px -11px; }
  .react-tel-input .bg {
    background-position: -64px -11px; }
  .react-tel-input .bh {
    background-position: -80px -11px; }
  .react-tel-input .bi {
    background-position: -96px -11px; }
  .react-tel-input .bj {
    background-position: -112px -11px; }
  .react-tel-input .bm {
    background-position: -128px -11px; }
  .react-tel-input .bn {
    background-position: -144px -11px; }
  .react-tel-input .bo {
    background-position: -160px -11px; }
  .react-tel-input .br {
    background-position: -176px -11px; }
  .react-tel-input .bs {
    background-position: -192px -11px; }
  .react-tel-input .bt {
    background-position: -208px -11px; }
  .react-tel-input .bw {
    background-position: -224px -11px; }
  .react-tel-input .by {
    background-position: -240px -11px; }
  .react-tel-input .bz {
    background-position: 0 -22px; }
  .react-tel-input .ca {
    background-position: -16px -22px; }
  .react-tel-input .cd {
    background-position: -32px -22px; }
  .react-tel-input .cf {
    background-position: -48px -22px; }
  .react-tel-input .cg {
    background-position: -64px -22px; }
  .react-tel-input .ch {
    background-position: -80px -22px; }
  .react-tel-input .ci {
    background-position: -96px -22px; }
  .react-tel-input .ck {
    background-position: -112px -22px; }
  .react-tel-input .cl {
    background-position: -128px -22px; }
  .react-tel-input .cm {
    background-position: -144px -22px; }
  .react-tel-input .cn {
    background-position: -160px -22px; }
  .react-tel-input .co {
    background-position: -176px -22px; }
  .react-tel-input .cr {
    background-position: -192px -22px; }
  .react-tel-input .cu {
    background-position: -208px -22px; }
  .react-tel-input .cv {
    background-position: -224px -22px; }
  .react-tel-input .cw {
    background-position: -240px -22px; }
  .react-tel-input .cy {
    background-position: 0 -33px; }
  .react-tel-input .cz {
    background-position: -16px -33px; }
  .react-tel-input .de {
    background-position: -32px -33px; }
  .react-tel-input .dj {
    background-position: -48px -33px; }
  .react-tel-input .dk {
    background-position: -64px -33px; }
  .react-tel-input .dm {
    background-position: -80px -33px; }
  .react-tel-input .do {
    background-position: -96px -33px; }
  .react-tel-input .dz {
    background-position: -112px -33px; }
  .react-tel-input .ec {
    background-position: -128px -33px; }
  .react-tel-input .ee {
    background-position: -144px -33px; }
  .react-tel-input .eg {
    background-position: -160px -33px; }
  .react-tel-input .er {
    background-position: -176px -33px; }
  .react-tel-input .es {
    background-position: -192px -33px; }
  .react-tel-input .et {
    background-position: -208px -33px; }
  .react-tel-input .fi {
    background-position: -224px -33px; }
  .react-tel-input .fj {
    background-position: -240px -33px; }
  .react-tel-input .fk {
    background-position: 0 -44px; }
  .react-tel-input .fm {
    background-position: -16px -44px; }
  .react-tel-input .fo {
    background-position: -32px -44px; }
  .react-tel-input .fr, .react-tel-input .bl, .react-tel-input .mf {
    background-position: -48px -44px; }
  .react-tel-input .ga {
    background-position: -64px -44px; }
  .react-tel-input .gb {
    background-position: -80px -44px; }
  .react-tel-input .gd {
    background-position: -96px -44px; }
  .react-tel-input .ge {
    background-position: -112px -44px; }
  .react-tel-input .gf {
    background-position: -128px -44px; }
  .react-tel-input .gh {
    background-position: -144px -44px; }
  .react-tel-input .gi {
    background-position: -160px -44px; }
  .react-tel-input .gl {
    background-position: -176px -44px; }
  .react-tel-input .gm {
    background-position: -192px -44px; }
  .react-tel-input .gn {
    background-position: -208px -44px; }
  .react-tel-input .gp {
    background-position: -224px -44px; }
  .react-tel-input .gq {
    background-position: -240px -44px; }
  .react-tel-input .gr {
    background-position: 0 -55px; }
  .react-tel-input .gt {
    background-position: -16px -55px; }
  .react-tel-input .gu {
    background-position: -32px -55px; }
  .react-tel-input .gw {
    background-position: -48px -55px; }
  .react-tel-input .gy {
    background-position: -64px -55px; }
  .react-tel-input .hk {
    background-position: -80px -55px; }
  .react-tel-input .hn {
    background-position: -96px -55px; }
  .react-tel-input .hr {
    background-position: -112px -55px; }
  .react-tel-input .ht {
    background-position: -128px -55px; }
  .react-tel-input .hu {
    background-position: -144px -55px; }
  .react-tel-input .id {
    background-position: -160px -55px; }
  .react-tel-input .ie {
    background-position: -176px -55px; }
  .react-tel-input .il {
    background-position: -192px -55px; }
  .react-tel-input .in {
    background-position: -208px -55px; }
  .react-tel-input .io {
    background-position: -224px -55px; }
  .react-tel-input .iq {
    background-position: -240px -55px; }
  .react-tel-input .ir {
    background-position: 0 -66px; }
  .react-tel-input .is {
    background-position: -16px -66px; }
  .react-tel-input .it {
    background-position: -32px -66px; }
  .react-tel-input .jm {
    background-position: -48px -66px; }
  .react-tel-input .jo {
    background-position: -64px -66px; }
  .react-tel-input .jp {
    background-position: -80px -66px; }
  .react-tel-input .ke {
    background-position: -96px -66px; }
  .react-tel-input .kg {
    background-position: -112px -66px; }
  .react-tel-input .kh {
    background-position: -128px -66px; }
  .react-tel-input .ki {
    background-position: -144px -66px; }
  .react-tel-input .xk {
    background-position: -128px -154px; }
  .react-tel-input .km {
    background-position: -160px -66px; }
  .react-tel-input .kn {
    background-position: -176px -66px; }
  .react-tel-input .kp {
    background-position: -192px -66px; }
  .react-tel-input .kr {
    background-position: -208px -66px; }
  .react-tel-input .kw {
    background-position: -224px -66px; }
  .react-tel-input .ky {
    background-position: -240px -66px; }
  .react-tel-input .kz {
    background-position: 0 -77px; }
  .react-tel-input .la {
    background-position: -16px -77px; }
  .react-tel-input .lb {
    background-position: -32px -77px; }
  .react-tel-input .lc {
    background-position: -48px -77px; }
  .react-tel-input .li {
    background-position: -64px -77px; }
  .react-tel-input .lk {
    background-position: -80px -77px; }
  .react-tel-input .lr {
    background-position: -96px -77px; }
  .react-tel-input .ls {
    background-position: -112px -77px; }
  .react-tel-input .lt {
    background-position: -128px -77px; }
  .react-tel-input .lu {
    background-position: -144px -77px; }
  .react-tel-input .lv {
    background-position: -160px -77px; }
  .react-tel-input .ly {
    background-position: -176px -77px; }
  .react-tel-input .ma {
    background-position: -192px -77px; }
  .react-tel-input .mc {
    background-position: -208px -77px; }
  .react-tel-input .md {
    background-position: -224px -77px; }
  .react-tel-input .me {
    background-position: -112px -154px;
    height: 12px; }
  .react-tel-input .mg {
    background-position: 0 -88px; }
  .react-tel-input .mh {
    background-position: -16px -88px; }
  .react-tel-input .mk {
    background-position: -32px -88px; }
  .react-tel-input .ml {
    background-position: -48px -88px; }
  .react-tel-input .mm {
    background-position: -64px -88px; }
  .react-tel-input .mn {
    background-position: -80px -88px; }
  .react-tel-input .mo {
    background-position: -96px -88px; }
  .react-tel-input .mp {
    background-position: -112px -88px; }
  .react-tel-input .mq {
    background-position: -128px -88px; }
  .react-tel-input .mr {
    background-position: -144px -88px; }
  .react-tel-input .ms {
    background-position: -160px -88px; }
  .react-tel-input .mt {
    background-position: -176px -88px; }
  .react-tel-input .mu {
    background-position: -192px -88px; }
  .react-tel-input .mv {
    background-position: -208px -88px; }
  .react-tel-input .mw {
    background-position: -224px -88px; }
  .react-tel-input .mx {
    background-position: -240px -88px; }
  .react-tel-input .my {
    background-position: 0 -99px; }
  .react-tel-input .mz {
    background-position: -16px -99px; }
  .react-tel-input .na {
    background-position: -32px -99px; }
  .react-tel-input .nc {
    background-position: -48px -99px; }
  .react-tel-input .ne {
    background-position: -64px -99px; }
  .react-tel-input .nf {
    background-position: -80px -99px; }
  .react-tel-input .ng {
    background-position: -96px -99px; }
  .react-tel-input .ni {
    background-position: -112px -99px; }
  .react-tel-input .nl, .react-tel-input .bq {
    background-position: -128px -99px; }
  .react-tel-input .no {
    background-position: -144px -99px; }
  .react-tel-input .np {
    background-position: -160px -99px; }
  .react-tel-input .nr {
    background-position: -176px -99px; }
  .react-tel-input .nu {
    background-position: -192px -99px; }
  .react-tel-input .nz {
    background-position: -208px -99px; }
  .react-tel-input .om {
    background-position: -224px -99px; }
  .react-tel-input .pa {
    background-position: -240px -99px; }
  .react-tel-input .pe {
    background-position: 0 -110px; }
  .react-tel-input .pf {
    background-position: -16px -110px; }
  .react-tel-input .pg {
    background-position: -32px -110px; }
  .react-tel-input .ph {
    background-position: -48px -110px; }
  .react-tel-input .pk {
    background-position: -64px -110px; }
  .react-tel-input .pl {
    background-position: -80px -110px; }
  .react-tel-input .pm {
    background-position: -96px -110px; }
  .react-tel-input .pr {
    background-position: -112px -110px; }
  .react-tel-input .ps {
    background-position: -128px -110px; }
  .react-tel-input .pt {
    background-position: -144px -110px; }
  .react-tel-input .pw {
    background-position: -160px -110px; }
  .react-tel-input .py {
    background-position: -176px -110px; }
  .react-tel-input .qa {
    background-position: -192px -110px; }
  .react-tel-input .re {
    background-position: -208px -110px; }
  .react-tel-input .ro {
    background-position: -224px -110px; }
  .react-tel-input .rs {
    background-position: -240px -110px; }
  .react-tel-input .ru {
    background-position: 0 -121px; }
  .react-tel-input .rw {
    background-position: -16px -121px; }
  .react-tel-input .sa {
    background-position: -32px -121px; }
  .react-tel-input .sb {
    background-position: -48px -121px; }
  .react-tel-input .sc {
    background-position: -64px -121px; }
  .react-tel-input .sd {
    background-position: -80px -121px; }
  .react-tel-input .se {
    background-position: -96px -121px; }
  .react-tel-input .sg {
    background-position: -112px -121px; }
  .react-tel-input .sh {
    background-position: -128px -121px; }
  .react-tel-input .si {
    background-position: -144px -121px; }
  .react-tel-input .sk {
    background-position: -160px -121px; }
  .react-tel-input .sl {
    background-position: -176px -121px; }
  .react-tel-input .sm {
    background-position: -192px -121px; }
  .react-tel-input .sn {
    background-position: -208px -121px; }
  .react-tel-input .so {
    background-position: -224px -121px; }
  .react-tel-input .sr {
    background-position: -240px -121px; }
  .react-tel-input .ss {
    background-position: 0 -132px; }
  .react-tel-input .st {
    background-position: -16px -132px; }
  .react-tel-input .sv {
    background-position: -32px -132px; }
  .react-tel-input .sx {
    background-position: -48px -132px; }
  .react-tel-input .sy {
    background-position: -64px -132px; }
  .react-tel-input .sz {
    background-position: -80px -132px; }
  .react-tel-input .tc {
    background-position: -96px -132px; }
  .react-tel-input .td {
    background-position: -112px -132px; }
  .react-tel-input .tg {
    background-position: -128px -132px; }
  .react-tel-input .th {
    background-position: -144px -132px; }
  .react-tel-input .tj {
    background-position: -160px -132px; }
  .react-tel-input .tk {
    background-position: -176px -132px; }
  .react-tel-input .tl {
    background-position: -192px -132px; }
  .react-tel-input .tm {
    background-position: -208px -132px; }
  .react-tel-input .tn {
    background-position: -224px -132px; }
  .react-tel-input .to {
    background-position: -240px -132px; }
  .react-tel-input .tr {
    background-position: 0 -143px; }
  .react-tel-input .tt {
    background-position: -16px -143px; }
  .react-tel-input .tv {
    background-position: -32px -143px; }
  .react-tel-input .tw {
    background-position: -48px -143px; }
  .react-tel-input .tz {
    background-position: -64px -143px; }
  .react-tel-input .ua {
    background-position: -80px -143px; }
  .react-tel-input .ug {
    background-position: -96px -143px; }
  .react-tel-input .us {
    background-position: -112px -143px; }
  .react-tel-input .uy {
    background-position: -128px -143px; }
  .react-tel-input .uz {
    background-position: -144px -143px; }
  .react-tel-input .va {
    background-position: -160px -143px; }
  .react-tel-input .vc {
    background-position: -176px -143px; }
  .react-tel-input .ve {
    background-position: -192px -143px; }
  .react-tel-input .vg {
    background-position: -208px -143px; }
  .react-tel-input .vi {
    background-position: -224px -143px; }
  .react-tel-input .vn {
    background-position: -240px -143px; }
  .react-tel-input .vu {
    background-position: 0 -154px; }
  .react-tel-input .wf {
    background-position: -16px -154px; }
  .react-tel-input .ws {
    background-position: -32px -154px; }
  .react-tel-input .ye {
    background-position: -48px -154px; }
  .react-tel-input .za {
    background-position: -64px -154px; }
  .react-tel-input .zm {
    background-position: -80px -154px; }
  .react-tel-input .zw {
    background-position: -96px -154px; }
  .react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .react-tel-input .hide {
    display: none; }
  .react-tel-input .v-hide {
    visibility: hidden; }

.react-tel-input {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%; }
  .react-tel-input :disabled {
    cursor: not-allowed; }
  .react-tel-input .flag {
    width: 16px;
    height: 11px;
    background: url("../img/flags.png"); }
  .react-tel-input .ad {
    background-position: -16px 0; }
  .react-tel-input .ae {
    background-position: -32px 0; }
  .react-tel-input .af {
    background-position: -48px 0; }
  .react-tel-input .ag {
    background-position: -64px 0; }
  .react-tel-input .ai {
    background-position: -80px 0; }
  .react-tel-input .al {
    background-position: -96px 0; }
  .react-tel-input .am {
    background-position: -112px 0; }
  .react-tel-input .ao {
    background-position: -128px 0; }
  .react-tel-input .ar {
    background-position: -144px 0; }
  .react-tel-input .as {
    background-position: -160px 0; }
  .react-tel-input .at {
    background-position: -176px 0; }
  .react-tel-input .au {
    background-position: -192px 0; }
  .react-tel-input .aw {
    background-position: -208px 0; }
  .react-tel-input .az {
    background-position: -224px 0; }
  .react-tel-input .ba {
    background-position: -240px 0; }
  .react-tel-input .bb {
    background-position: 0 -11px; }
  .react-tel-input .bd {
    background-position: -16px -11px; }
  .react-tel-input .be {
    background-position: -32px -11px; }
  .react-tel-input .bf {
    background-position: -48px -11px; }
  .react-tel-input .bg {
    background-position: -64px -11px; }
  .react-tel-input .bh {
    background-position: -80px -11px; }
  .react-tel-input .bi {
    background-position: -96px -11px; }
  .react-tel-input .bj {
    background-position: -112px -11px; }
  .react-tel-input .bm {
    background-position: -128px -11px; }
  .react-tel-input .bn {
    background-position: -144px -11px; }
  .react-tel-input .bo {
    background-position: -160px -11px; }
  .react-tel-input .br {
    background-position: -176px -11px; }
  .react-tel-input .bs {
    background-position: -192px -11px; }
  .react-tel-input .bt {
    background-position: -208px -11px; }
  .react-tel-input .bw {
    background-position: -224px -11px; }
  .react-tel-input .by {
    background-position: -240px -11px; }
  .react-tel-input .bz {
    background-position: 0 -22px; }
  .react-tel-input .ca {
    background-position: -16px -22px; }
  .react-tel-input .cd {
    background-position: -32px -22px; }
  .react-tel-input .cf {
    background-position: -48px -22px; }
  .react-tel-input .cg {
    background-position: -64px -22px; }
  .react-tel-input .ch {
    background-position: -80px -22px; }
  .react-tel-input .ci {
    background-position: -96px -22px; }
  .react-tel-input .ck {
    background-position: -112px -22px; }
  .react-tel-input .cl {
    background-position: -128px -22px; }
  .react-tel-input .cm {
    background-position: -144px -22px; }
  .react-tel-input .cn {
    background-position: -160px -22px; }
  .react-tel-input .co {
    background-position: -176px -22px; }
  .react-tel-input .cr {
    background-position: -192px -22px; }
  .react-tel-input .cu {
    background-position: -208px -22px; }
  .react-tel-input .cv {
    background-position: -224px -22px; }
  .react-tel-input .cw {
    background-position: -240px -22px; }
  .react-tel-input .cy {
    background-position: 0 -33px; }
  .react-tel-input .cz {
    background-position: -16px -33px; }
  .react-tel-input .de {
    background-position: -32px -33px; }
  .react-tel-input .dj {
    background-position: -48px -33px; }
  .react-tel-input .dk {
    background-position: -64px -33px; }
  .react-tel-input .dm {
    background-position: -80px -33px; }
  .react-tel-input .do {
    background-position: -96px -33px; }
  .react-tel-input .dz {
    background-position: -112px -33px; }
  .react-tel-input .ec {
    background-position: -128px -33px; }
  .react-tel-input .ee {
    background-position: -144px -33px; }
  .react-tel-input .eg {
    background-position: -160px -33px; }
  .react-tel-input .er {
    background-position: -176px -33px; }
  .react-tel-input .es {
    background-position: -192px -33px; }
  .react-tel-input .et {
    background-position: -208px -33px; }
  .react-tel-input .fi {
    background-position: -224px -33px; }
  .react-tel-input .fj {
    background-position: -240px -33px; }
  .react-tel-input .fk {
    background-position: 0 -44px; }
  .react-tel-input .fm {
    background-position: -16px -44px; }
  .react-tel-input .fo {
    background-position: -32px -44px; }
  .react-tel-input .fr, .react-tel-input .bl, .react-tel-input .mf {
    background-position: -48px -44px; }
  .react-tel-input .ga {
    background-position: -64px -44px; }
  .react-tel-input .gb {
    background-position: -80px -44px; }
  .react-tel-input .gd {
    background-position: -96px -44px; }
  .react-tel-input .ge {
    background-position: -112px -44px; }
  .react-tel-input .gf {
    background-position: -128px -44px; }
  .react-tel-input .gh {
    background-position: -144px -44px; }
  .react-tel-input .gi {
    background-position: -160px -44px; }
  .react-tel-input .gl {
    background-position: -176px -44px; }
  .react-tel-input .gm {
    background-position: -192px -44px; }
  .react-tel-input .gn {
    background-position: -208px -44px; }
  .react-tel-input .gp {
    background-position: -224px -44px; }
  .react-tel-input .gq {
    background-position: -240px -44px; }
  .react-tel-input .gr {
    background-position: 0 -55px; }
  .react-tel-input .gt {
    background-position: -16px -55px; }
  .react-tel-input .gu {
    background-position: -32px -55px; }
  .react-tel-input .gw {
    background-position: -48px -55px; }
  .react-tel-input .gy {
    background-position: -64px -55px; }
  .react-tel-input .hk {
    background-position: -80px -55px; }
  .react-tel-input .hn {
    background-position: -96px -55px; }
  .react-tel-input .hr {
    background-position: -112px -55px; }
  .react-tel-input .ht {
    background-position: -128px -55px; }
  .react-tel-input .hu {
    background-position: -144px -55px; }
  .react-tel-input .id {
    background-position: -160px -55px; }
  .react-tel-input .ie {
    background-position: -176px -55px; }
  .react-tel-input .il {
    background-position: -192px -55px; }
  .react-tel-input .in {
    background-position: -208px -55px; }
  .react-tel-input .io {
    background-position: -224px -55px; }
  .react-tel-input .iq {
    background-position: -240px -55px; }
  .react-tel-input .ir {
    background-position: 0 -66px; }
  .react-tel-input .is {
    background-position: -16px -66px; }
  .react-tel-input .it {
    background-position: -32px -66px; }
  .react-tel-input .jm {
    background-position: -48px -66px; }
  .react-tel-input .jo {
    background-position: -64px -66px; }
  .react-tel-input .jp {
    background-position: -80px -66px; }
  .react-tel-input .ke {
    background-position: -96px -66px; }
  .react-tel-input .kg {
    background-position: -112px -66px; }
  .react-tel-input .kh {
    background-position: -128px -66px; }
  .react-tel-input .ki {
    background-position: -144px -66px; }
  .react-tel-input .xk {
    background-position: -128px -154px; }
  .react-tel-input .km {
    background-position: -160px -66px; }
  .react-tel-input .kn {
    background-position: -176px -66px; }
  .react-tel-input .kp {
    background-position: -192px -66px; }
  .react-tel-input .kr {
    background-position: -208px -66px; }
  .react-tel-input .kw {
    background-position: -224px -66px; }
  .react-tel-input .ky {
    background-position: -240px -66px; }
  .react-tel-input .kz {
    background-position: 0 -77px; }
  .react-tel-input .la {
    background-position: -16px -77px; }
  .react-tel-input .lb {
    background-position: -32px -77px; }
  .react-tel-input .lc {
    background-position: -48px -77px; }
  .react-tel-input .li {
    background-position: -64px -77px; }
  .react-tel-input .lk {
    background-position: -80px -77px; }
  .react-tel-input .lr {
    background-position: -96px -77px; }
  .react-tel-input .ls {
    background-position: -112px -77px; }
  .react-tel-input .lt {
    background-position: -128px -77px; }
  .react-tel-input .lu {
    background-position: -144px -77px; }
  .react-tel-input .lv {
    background-position: -160px -77px; }
  .react-tel-input .ly {
    background-position: -176px -77px; }
  .react-tel-input .ma {
    background-position: -192px -77px; }
  .react-tel-input .mc {
    background-position: -208px -77px; }
  .react-tel-input .md {
    background-position: -224px -77px; }
  .react-tel-input .me {
    background-position: -112px -154px;
    height: 12px; }
  .react-tel-input .mg {
    background-position: 0 -88px; }
  .react-tel-input .mh {
    background-position: -16px -88px; }
  .react-tel-input .mk {
    background-position: -32px -88px; }
  .react-tel-input .ml {
    background-position: -48px -88px; }
  .react-tel-input .mm {
    background-position: -64px -88px; }
  .react-tel-input .mn {
    background-position: -80px -88px; }
  .react-tel-input .mo {
    background-position: -96px -88px; }
  .react-tel-input .mp {
    background-position: -112px -88px; }
  .react-tel-input .mq {
    background-position: -128px -88px; }
  .react-tel-input .mr {
    background-position: -144px -88px; }
  .react-tel-input .ms {
    background-position: -160px -88px; }
  .react-tel-input .mt {
    background-position: -176px -88px; }
  .react-tel-input .mu {
    background-position: -192px -88px; }
  .react-tel-input .mv {
    background-position: -208px -88px; }
  .react-tel-input .mw {
    background-position: -224px -88px; }
  .react-tel-input .mx {
    background-position: -240px -88px; }
  .react-tel-input .my {
    background-position: 0 -99px; }
  .react-tel-input .mz {
    background-position: -16px -99px; }
  .react-tel-input .na {
    background-position: -32px -99px; }
  .react-tel-input .nc {
    background-position: -48px -99px; }
  .react-tel-input .ne {
    background-position: -64px -99px; }
  .react-tel-input .nf {
    background-position: -80px -99px; }
  .react-tel-input .ng {
    background-position: -96px -99px; }
  .react-tel-input .ni {
    background-position: -112px -99px; }
  .react-tel-input .nl, .react-tel-input .bq {
    background-position: -128px -99px; }
  .react-tel-input .no {
    background-position: -144px -99px; }
  .react-tel-input .np {
    background-position: -160px -99px; }
  .react-tel-input .nr {
    background-position: -176px -99px; }
  .react-tel-input .nu {
    background-position: -192px -99px; }
  .react-tel-input .nz {
    background-position: -208px -99px; }
  .react-tel-input .om {
    background-position: -224px -99px; }
  .react-tel-input .pa {
    background-position: -240px -99px; }
  .react-tel-input .pe {
    background-position: 0 -110px; }
  .react-tel-input .pf {
    background-position: -16px -110px; }
  .react-tel-input .pg {
    background-position: -32px -110px; }
  .react-tel-input .ph {
    background-position: -48px -110px; }
  .react-tel-input .pk {
    background-position: -64px -110px; }
  .react-tel-input .pl {
    background-position: -80px -110px; }
  .react-tel-input .pm {
    background-position: -96px -110px; }
  .react-tel-input .pr {
    background-position: -112px -110px; }
  .react-tel-input .ps {
    background-position: -128px -110px; }
  .react-tel-input .pt {
    background-position: -144px -110px; }
  .react-tel-input .pw {
    background-position: -160px -110px; }
  .react-tel-input .py {
    background-position: -176px -110px; }
  .react-tel-input .qa {
    background-position: -192px -110px; }
  .react-tel-input .re {
    background-position: -208px -110px; }
  .react-tel-input .ro {
    background-position: -224px -110px; }
  .react-tel-input .rs {
    background-position: -240px -110px; }
  .react-tel-input .ru {
    background-position: 0 -121px; }
  .react-tel-input .rw {
    background-position: -16px -121px; }
  .react-tel-input .sa {
    background-position: -32px -121px; }
  .react-tel-input .sb {
    background-position: -48px -121px; }
  .react-tel-input .sc {
    background-position: -64px -121px; }
  .react-tel-input .sd {
    background-position: -80px -121px; }
  .react-tel-input .se {
    background-position: -96px -121px; }
  .react-tel-input .sg {
    background-position: -112px -121px; }
  .react-tel-input .sh {
    background-position: -128px -121px; }
  .react-tel-input .si {
    background-position: -144px -121px; }
  .react-tel-input .sk {
    background-position: -160px -121px; }
  .react-tel-input .sl {
    background-position: -176px -121px; }
  .react-tel-input .sm {
    background-position: -192px -121px; }
  .react-tel-input .sn {
    background-position: -208px -121px; }
  .react-tel-input .so {
    background-position: -224px -121px; }
  .react-tel-input .sr {
    background-position: -240px -121px; }
  .react-tel-input .ss {
    background-position: 0 -132px; }
  .react-tel-input .st {
    background-position: -16px -132px; }
  .react-tel-input .sv {
    background-position: -32px -132px; }
  .react-tel-input .sx {
    background-position: -48px -132px; }
  .react-tel-input .sy {
    background-position: -64px -132px; }
  .react-tel-input .sz {
    background-position: -80px -132px; }
  .react-tel-input .tc {
    background-position: -96px -132px; }
  .react-tel-input .td {
    background-position: -112px -132px; }
  .react-tel-input .tg {
    background-position: -128px -132px; }
  .react-tel-input .th {
    background-position: -144px -132px; }
  .react-tel-input .tj {
    background-position: -160px -132px; }
  .react-tel-input .tk {
    background-position: -176px -132px; }
  .react-tel-input .tl {
    background-position: -192px -132px; }
  .react-tel-input .tm {
    background-position: -208px -132px; }
  .react-tel-input .tn {
    background-position: -224px -132px; }
  .react-tel-input .to {
    background-position: -240px -132px; }
  .react-tel-input .tr {
    background-position: 0 -143px; }
  .react-tel-input .tt {
    background-position: -16px -143px; }
  .react-tel-input .tv {
    background-position: -32px -143px; }
  .react-tel-input .tw {
    background-position: -48px -143px; }
  .react-tel-input .tz {
    background-position: -64px -143px; }
  .react-tel-input .ua {
    background-position: -80px -143px; }
  .react-tel-input .ug {
    background-position: -96px -143px; }
  .react-tel-input .us {
    background-position: -112px -143px; }
  .react-tel-input .uy {
    background-position: -128px -143px; }
  .react-tel-input .uz {
    background-position: -144px -143px; }
  .react-tel-input .va {
    background-position: -160px -143px; }
  .react-tel-input .vc {
    background-position: -176px -143px; }
  .react-tel-input .ve {
    background-position: -192px -143px; }
  .react-tel-input .vg {
    background-position: -208px -143px; }
  .react-tel-input .vi {
    background-position: -224px -143px; }
  .react-tel-input .vn {
    background-position: -240px -143px; }
  .react-tel-input .vu {
    background-position: 0 -154px; }
  .react-tel-input .wf {
    background-position: -16px -154px; }
  .react-tel-input .ws {
    background-position: -32px -154px; }
  .react-tel-input .ye {
    background-position: -48px -154px; }
  .react-tel-input .za {
    background-position: -64px -154px; }
  .react-tel-input .zm {
    background-position: -80px -154px; }
  .react-tel-input .zw {
    background-position: -96px -154px; }
  .react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .react-tel-input .hide {
    display: none; }
  .react-tel-input .v-hide {
    visibility: hidden; }

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100%;
  outline: none; }
  .react-tel-input .form-control.invalid-number {
    border: 1px solid #d79f9f;
    background-color: #FAF0F0;
    border-left-color: #cacaca; }
    .react-tel-input .form-control.invalid-number:focus {
      border: 1px solid #d79f9f;
      border-left-color: #cacaca;
      background-color: #FAF0F0; }
  .react-tel-input .form-control.open {
    z-index: 2; }

.react-tel-input .flag-dropdown {
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px; }
  .react-tel-input .flag-dropdown.open {
    z-index: 2;
    background: #fff;
    border-radius: 3px 0 0 0; }
    .react-tel-input .flag-dropdown.open .selected-flag {
      background: #fff;
      border-radius: 3px 0 0 0; }
  .react-tel-input .flag-dropdown:hover, .react-tel-input .flag-dropdown:focus {
    cursor: pointer; }
    .react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag {
      background-color: #fff; }

.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default; }
  .react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
    background-color: transparent; }

.react-tel-input .selected-flag {
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px; }
  .react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -5px; }
  .react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -2px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555; }
    .react-tel-input .selected-flag .arrow.up {
      border-top: none;
      border-bottom: 4px solid #555; }

.react-tel-input .country-list {
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px; }
  .react-tel-input .country-list .flag {
    display: inline-block; }
  .react-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc; }
  .react-tel-input .country-list .country {
    padding: 7px 9px; }
    .react-tel-input .country-list .country .dial-code {
      color: #6b6b6b; }
    .react-tel-input .country-list .country:hover {
      background-color: #f1f1f1; }
    .react-tel-input .country-list .country.highlight {
      background-color: #f1f1f1; }
  .react-tel-input .country-list .flag {
    margin-right: 7px;
    margin-top: 2px; }
  .react-tel-input .country-list .country-name {
    margin-right: 6px; }
  .react-tel-input .country-list .search {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px 0 6px 10px; }
  .react-tel-input .country-list .search-emoji {
    font-size: 15px; }
  .react-tel-input .country-list .search-box {
    border: 1px solid #cacaca;
    border-radius: 3px;
    font-size: 15px;
    line-height: 15px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none; }
  .react-tel-input .country-list .no-entries-message {
    padding: 7px 10px 11px;
    opacity: .7; }

.react-tel-input {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%; }
  .react-tel-input :disabled {
    cursor: not-allowed; }
  .react-tel-input .flag {
    width: 16px;
    height: 11px;
    background: url("../img/flags.png"); }
  .react-tel-input .ad {
    background-position: -16px 0; }
  .react-tel-input .ae {
    background-position: -32px 0; }
  .react-tel-input .af {
    background-position: -48px 0; }
  .react-tel-input .ag {
    background-position: -64px 0; }
  .react-tel-input .ai {
    background-position: -80px 0; }
  .react-tel-input .al {
    background-position: -96px 0; }
  .react-tel-input .am {
    background-position: -112px 0; }
  .react-tel-input .ao {
    background-position: -128px 0; }
  .react-tel-input .ar {
    background-position: -144px 0; }
  .react-tel-input .as {
    background-position: -160px 0; }
  .react-tel-input .at {
    background-position: -176px 0; }
  .react-tel-input .au {
    background-position: -192px 0; }
  .react-tel-input .aw {
    background-position: -208px 0; }
  .react-tel-input .az {
    background-position: -224px 0; }
  .react-tel-input .ba {
    background-position: -240px 0; }
  .react-tel-input .bb {
    background-position: 0 -11px; }
  .react-tel-input .bd {
    background-position: -16px -11px; }
  .react-tel-input .be {
    background-position: -32px -11px; }
  .react-tel-input .bf {
    background-position: -48px -11px; }
  .react-tel-input .bg {
    background-position: -64px -11px; }
  .react-tel-input .bh {
    background-position: -80px -11px; }
  .react-tel-input .bi {
    background-position: -96px -11px; }
  .react-tel-input .bj {
    background-position: -112px -11px; }
  .react-tel-input .bm {
    background-position: -128px -11px; }
  .react-tel-input .bn {
    background-position: -144px -11px; }
  .react-tel-input .bo {
    background-position: -160px -11px; }
  .react-tel-input .br {
    background-position: -176px -11px; }
  .react-tel-input .bs {
    background-position: -192px -11px; }
  .react-tel-input .bt {
    background-position: -208px -11px; }
  .react-tel-input .bw {
    background-position: -224px -11px; }
  .react-tel-input .by {
    background-position: -240px -11px; }
  .react-tel-input .bz {
    background-position: 0 -22px; }
  .react-tel-input .ca {
    background-position: -16px -22px; }
  .react-tel-input .cd {
    background-position: -32px -22px; }
  .react-tel-input .cf {
    background-position: -48px -22px; }
  .react-tel-input .cg {
    background-position: -64px -22px; }
  .react-tel-input .ch {
    background-position: -80px -22px; }
  .react-tel-input .ci {
    background-position: -96px -22px; }
  .react-tel-input .ck {
    background-position: -112px -22px; }
  .react-tel-input .cl {
    background-position: -128px -22px; }
  .react-tel-input .cm {
    background-position: -144px -22px; }
  .react-tel-input .cn {
    background-position: -160px -22px; }
  .react-tel-input .co {
    background-position: -176px -22px; }
  .react-tel-input .cr {
    background-position: -192px -22px; }
  .react-tel-input .cu {
    background-position: -208px -22px; }
  .react-tel-input .cv {
    background-position: -224px -22px; }
  .react-tel-input .cw {
    background-position: -240px -22px; }
  .react-tel-input .cy {
    background-position: 0 -33px; }
  .react-tel-input .cz {
    background-position: -16px -33px; }
  .react-tel-input .de {
    background-position: -32px -33px; }
  .react-tel-input .dj {
    background-position: -48px -33px; }
  .react-tel-input .dk {
    background-position: -64px -33px; }
  .react-tel-input .dm {
    background-position: -80px -33px; }
  .react-tel-input .do {
    background-position: -96px -33px; }
  .react-tel-input .dz {
    background-position: -112px -33px; }
  .react-tel-input .ec {
    background-position: -128px -33px; }
  .react-tel-input .ee {
    background-position: -144px -33px; }
  .react-tel-input .eg {
    background-position: -160px -33px; }
  .react-tel-input .er {
    background-position: -176px -33px; }
  .react-tel-input .es {
    background-position: -192px -33px; }
  .react-tel-input .et {
    background-position: -208px -33px; }
  .react-tel-input .fi {
    background-position: -224px -33px; }
  .react-tel-input .fj {
    background-position: -240px -33px; }
  .react-tel-input .fk {
    background-position: 0 -44px; }
  .react-tel-input .fm {
    background-position: -16px -44px; }
  .react-tel-input .fo {
    background-position: -32px -44px; }
  .react-tel-input .fr, .react-tel-input .bl, .react-tel-input .mf {
    background-position: -48px -44px; }
  .react-tel-input .ga {
    background-position: -64px -44px; }
  .react-tel-input .gb {
    background-position: -80px -44px; }
  .react-tel-input .gd {
    background-position: -96px -44px; }
  .react-tel-input .ge {
    background-position: -112px -44px; }
  .react-tel-input .gf {
    background-position: -128px -44px; }
  .react-tel-input .gh {
    background-position: -144px -44px; }
  .react-tel-input .gi {
    background-position: -160px -44px; }
  .react-tel-input .gl {
    background-position: -176px -44px; }
  .react-tel-input .gm {
    background-position: -192px -44px; }
  .react-tel-input .gn {
    background-position: -208px -44px; }
  .react-tel-input .gp {
    background-position: -224px -44px; }
  .react-tel-input .gq {
    background-position: -240px -44px; }
  .react-tel-input .gr {
    background-position: 0 -55px; }
  .react-tel-input .gt {
    background-position: -16px -55px; }
  .react-tel-input .gu {
    background-position: -32px -55px; }
  .react-tel-input .gw {
    background-position: -48px -55px; }
  .react-tel-input .gy {
    background-position: -64px -55px; }
  .react-tel-input .hk {
    background-position: -80px -55px; }
  .react-tel-input .hn {
    background-position: -96px -55px; }
  .react-tel-input .hr {
    background-position: -112px -55px; }
  .react-tel-input .ht {
    background-position: -128px -55px; }
  .react-tel-input .hu {
    background-position: -144px -55px; }
  .react-tel-input .id {
    background-position: -160px -55px; }
  .react-tel-input .ie {
    background-position: -176px -55px; }
  .react-tel-input .il {
    background-position: -192px -55px; }
  .react-tel-input .in {
    background-position: -208px -55px; }
  .react-tel-input .io {
    background-position: -224px -55px; }
  .react-tel-input .iq {
    background-position: -240px -55px; }
  .react-tel-input .ir {
    background-position: 0 -66px; }
  .react-tel-input .is {
    background-position: -16px -66px; }
  .react-tel-input .it {
    background-position: -32px -66px; }
  .react-tel-input .jm {
    background-position: -48px -66px; }
  .react-tel-input .jo {
    background-position: -64px -66px; }
  .react-tel-input .jp {
    background-position: -80px -66px; }
  .react-tel-input .ke {
    background-position: -96px -66px; }
  .react-tel-input .kg {
    background-position: -112px -66px; }
  .react-tel-input .kh {
    background-position: -128px -66px; }
  .react-tel-input .ki {
    background-position: -144px -66px; }
  .react-tel-input .xk {
    background-position: -128px -154px; }
  .react-tel-input .km {
    background-position: -160px -66px; }
  .react-tel-input .kn {
    background-position: -176px -66px; }
  .react-tel-input .kp {
    background-position: -192px -66px; }
  .react-tel-input .kr {
    background-position: -208px -66px; }
  .react-tel-input .kw {
    background-position: -224px -66px; }
  .react-tel-input .ky {
    background-position: -240px -66px; }
  .react-tel-input .kz {
    background-position: 0 -77px; }
  .react-tel-input .la {
    background-position: -16px -77px; }
  .react-tel-input .lb {
    background-position: -32px -77px; }
  .react-tel-input .lc {
    background-position: -48px -77px; }
  .react-tel-input .li {
    background-position: -64px -77px; }
  .react-tel-input .lk {
    background-position: -80px -77px; }
  .react-tel-input .lr {
    background-position: -96px -77px; }
  .react-tel-input .ls {
    background-position: -112px -77px; }
  .react-tel-input .lt {
    background-position: -128px -77px; }
  .react-tel-input .lu {
    background-position: -144px -77px; }
  .react-tel-input .lv {
    background-position: -160px -77px; }
  .react-tel-input .ly {
    background-position: -176px -77px; }
  .react-tel-input .ma {
    background-position: -192px -77px; }
  .react-tel-input .mc {
    background-position: -208px -77px; }
  .react-tel-input .md {
    background-position: -224px -77px; }
  .react-tel-input .me {
    background-position: -112px -154px;
    height: 12px; }
  .react-tel-input .mg {
    background-position: 0 -88px; }
  .react-tel-input .mh {
    background-position: -16px -88px; }
  .react-tel-input .mk {
    background-position: -32px -88px; }
  .react-tel-input .ml {
    background-position: -48px -88px; }
  .react-tel-input .mm {
    background-position: -64px -88px; }
  .react-tel-input .mn {
    background-position: -80px -88px; }
  .react-tel-input .mo {
    background-position: -96px -88px; }
  .react-tel-input .mp {
    background-position: -112px -88px; }
  .react-tel-input .mq {
    background-position: -128px -88px; }
  .react-tel-input .mr {
    background-position: -144px -88px; }
  .react-tel-input .ms {
    background-position: -160px -88px; }
  .react-tel-input .mt {
    background-position: -176px -88px; }
  .react-tel-input .mu {
    background-position: -192px -88px; }
  .react-tel-input .mv {
    background-position: -208px -88px; }
  .react-tel-input .mw {
    background-position: -224px -88px; }
  .react-tel-input .mx {
    background-position: -240px -88px; }
  .react-tel-input .my {
    background-position: 0 -99px; }
  .react-tel-input .mz {
    background-position: -16px -99px; }
  .react-tel-input .na {
    background-position: -32px -99px; }
  .react-tel-input .nc {
    background-position: -48px -99px; }
  .react-tel-input .ne {
    background-position: -64px -99px; }
  .react-tel-input .nf {
    background-position: -80px -99px; }
  .react-tel-input .ng {
    background-position: -96px -99px; }
  .react-tel-input .ni {
    background-position: -112px -99px; }
  .react-tel-input .nl, .react-tel-input .bq {
    background-position: -128px -99px; }
  .react-tel-input .no {
    background-position: -144px -99px; }
  .react-tel-input .np {
    background-position: -160px -99px; }
  .react-tel-input .nr {
    background-position: -176px -99px; }
  .react-tel-input .nu {
    background-position: -192px -99px; }
  .react-tel-input .nz {
    background-position: -208px -99px; }
  .react-tel-input .om {
    background-position: -224px -99px; }
  .react-tel-input .pa {
    background-position: -240px -99px; }
  .react-tel-input .pe {
    background-position: 0 -110px; }
  .react-tel-input .pf {
    background-position: -16px -110px; }
  .react-tel-input .pg {
    background-position: -32px -110px; }
  .react-tel-input .ph {
    background-position: -48px -110px; }
  .react-tel-input .pk {
    background-position: -64px -110px; }
  .react-tel-input .pl {
    background-position: -80px -110px; }
  .react-tel-input .pm {
    background-position: -96px -110px; }
  .react-tel-input .pr {
    background-position: -112px -110px; }
  .react-tel-input .ps {
    background-position: -128px -110px; }
  .react-tel-input .pt {
    background-position: -144px -110px; }
  .react-tel-input .pw {
    background-position: -160px -110px; }
  .react-tel-input .py {
    background-position: -176px -110px; }
  .react-tel-input .qa {
    background-position: -192px -110px; }
  .react-tel-input .re {
    background-position: -208px -110px; }
  .react-tel-input .ro {
    background-position: -224px -110px; }
  .react-tel-input .rs {
    background-position: -240px -110px; }
  .react-tel-input .ru {
    background-position: 0 -121px; }
  .react-tel-input .rw {
    background-position: -16px -121px; }
  .react-tel-input .sa {
    background-position: -32px -121px; }
  .react-tel-input .sb {
    background-position: -48px -121px; }
  .react-tel-input .sc {
    background-position: -64px -121px; }
  .react-tel-input .sd {
    background-position: -80px -121px; }
  .react-tel-input .se {
    background-position: -96px -121px; }
  .react-tel-input .sg {
    background-position: -112px -121px; }
  .react-tel-input .sh {
    background-position: -128px -121px; }
  .react-tel-input .si {
    background-position: -144px -121px; }
  .react-tel-input .sk {
    background-position: -160px -121px; }
  .react-tel-input .sl {
    background-position: -176px -121px; }
  .react-tel-input .sm {
    background-position: -192px -121px; }
  .react-tel-input .sn {
    background-position: -208px -121px; }
  .react-tel-input .so {
    background-position: -224px -121px; }
  .react-tel-input .sr {
    background-position: -240px -121px; }
  .react-tel-input .ss {
    background-position: 0 -132px; }
  .react-tel-input .st {
    background-position: -16px -132px; }
  .react-tel-input .sv {
    background-position: -32px -132px; }
  .react-tel-input .sx {
    background-position: -48px -132px; }
  .react-tel-input .sy {
    background-position: -64px -132px; }
  .react-tel-input .sz {
    background-position: -80px -132px; }
  .react-tel-input .tc {
    background-position: -96px -132px; }
  .react-tel-input .td {
    background-position: -112px -132px; }
  .react-tel-input .tg {
    background-position: -128px -132px; }
  .react-tel-input .th {
    background-position: -144px -132px; }
  .react-tel-input .tj {
    background-position: -160px -132px; }
  .react-tel-input .tk {
    background-position: -176px -132px; }
  .react-tel-input .tl {
    background-position: -192px -132px; }
  .react-tel-input .tm {
    background-position: -208px -132px; }
  .react-tel-input .tn {
    background-position: -224px -132px; }
  .react-tel-input .to {
    background-position: -240px -132px; }
  .react-tel-input .tr {
    background-position: 0 -143px; }
  .react-tel-input .tt {
    background-position: -16px -143px; }
  .react-tel-input .tv {
    background-position: -32px -143px; }
  .react-tel-input .tw {
    background-position: -48px -143px; }
  .react-tel-input .tz {
    background-position: -64px -143px; }
  .react-tel-input .ua {
    background-position: -80px -143px; }
  .react-tel-input .ug {
    background-position: -96px -143px; }
  .react-tel-input .us {
    background-position: -112px -143px; }
  .react-tel-input .uy {
    background-position: -128px -143px; }
  .react-tel-input .uz {
    background-position: -144px -143px; }
  .react-tel-input .va {
    background-position: -160px -143px; }
  .react-tel-input .vc {
    background-position: -176px -143px; }
  .react-tel-input .ve {
    background-position: -192px -143px; }
  .react-tel-input .vg {
    background-position: -208px -143px; }
  .react-tel-input .vi {
    background-position: -224px -143px; }
  .react-tel-input .vn {
    background-position: -240px -143px; }
  .react-tel-input .vu {
    background-position: 0 -154px; }
  .react-tel-input .wf {
    background-position: -16px -154px; }
  .react-tel-input .ws {
    background-position: -32px -154px; }
  .react-tel-input .ye {
    background-position: -48px -154px; }
  .react-tel-input .za {
    background-position: -64px -154px; }
  .react-tel-input .zm {
    background-position: -80px -154px; }
  .react-tel-input .zw {
    background-position: -96px -154px; }
  .react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .react-tel-input .hide {
    display: none; }
  .react-tel-input .v-hide {
    visibility: hidden; }

.react-tel-input .form-control {
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 5px 5px 5px 58px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
  box-shadow: none;
  height: 28px; }
  .react-tel-input .form-control:focus, .react-tel-input .form-control.open {
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .react-tel-input .form-control.invalid-number {
    border: 1px solid #f44336;
    border-left-color: #cacaca; }
    .react-tel-input .form-control.invalid-number:focus {
      box-shadow: 0 0 0 1px #f44336; }
  .react-tel-input .form-control.open {
    z-index: 2; }

.react-tel-input .flag-dropdown {
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px; }
  .react-tel-input .flag-dropdown.open {
    z-index: 2; }
  .react-tel-input .flag-dropdown:hover, .react-tel-input .flag-dropdown:focus {
    cursor: pointer; }

.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default; }
  .react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
    background-color: transparent; }

.react-tel-input .selected-flag {
  position: relative;
  width: 52px;
  height: 100%;
  padding: 0 0 0 11px;
  border-radius: 3px 0 0 3px; }
  .react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -7px; }
  .react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -1px;
    left: 29px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555; }
    .react-tel-input .selected-flag .arrow.up {
      border-top: none;
      border-bottom: 4px solid #555; }

.react-tel-input .country-list {
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 300px;
  max-height: 220px;
  overflow-y: scroll;
  border-radius: 7px; }
  .react-tel-input .country-list .flag {
    display: inline-block;
    position: absolute;
    left: 13px;
    top: 8px; }
  .react-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc; }
  .react-tel-input .country-list .country {
    position: relative;
    padding: 12px 9px 13px 46px; }
    .react-tel-input .country-list .country .dial-code {
      color: #6b6b6b; }
    .react-tel-input .country-list .country:hover {
      background-color: #f1f1f1; }
    .react-tel-input .country-list .country.highlight {
      background-color: #f1f1f1; }
  .react-tel-input .country-list .flag {
    margin-right: 7px;
    margin-top: 8px; }
  .react-tel-input .country-list .country-name {
    margin-right: 6px; }
  .react-tel-input .country-list .search {
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px 0 6px 10px; }
  .react-tel-input .country-list .search-emoji {
    display: none;
    font-size: 15px; }
  .react-tel-input .country-list .search-box {
    border: 1px solid #cacaca;
    border-radius: 3px;
    font-size: 15px;
    line-height: 15px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none; }
  .react-tel-input .country-list .no-entries-message {
    padding: 7px 10px 11px;
    opacity: 0.7; }

.card, .card-small, .card-large, .card-map, .card-for-basic-report, .card-large-merchant, .card-large-group, .card-large-report, .card-detail {
  border-radius: 4px;
  box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #ffffff;
  border: none;
  margin: 15px;
  box-sizing: border-box;
  word-wrap: break-word;
  height: auto;
  flex: 1;
  position: relative; }
  .card .image, .card-small .image, .card-large .image, .card-map .image, .card-for-basic-report .image, .card-large-merchant .image, .card-large-group .image, .card-large-report .image, .card-detail .image {
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 4px 4px 0 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d; }
    .card .image img, .card-small .image img, .card-large .image img, .card-map .image img, .card-for-basic-report .image img, .card-large-merchant .image img, .card-large-group .image img, .card-large-report .image img, .card-detail .image img {
      width: 100%; }
  .card .filter, .card-small .filter, .card-large .filter, .card-map .filter, .card-for-basic-report .filter, .card-large-merchant .filter, .card-large-group .filter, .card-large-report .filter, .card-detail .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0); }
    .card .filter .btn, .card-small .filter .btn, .card-large .filter .btn, .card-map .filter .btn, .card-for-basic-report .filter .btn, .card-large-merchant .filter .btn, .card-large-group .filter .btn, .card-large-report .filter .btn, .card-detail .filter .btn {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  .card:hover .filter, .card-small:hover .filter, .card-large:hover .filter, .card-map:hover .filter, .card-for-basic-report:hover .filter, .card-large-merchant:hover .filter, .card-large-group:hover .filter, .card-large-report:hover .filter, .card-detail:hover .filter {
    opacity: 1;
    filter: alpha(opacity=100); }
  .card .btn-hover, .card-small .btn-hover, .card-large .btn-hover, .card-map .btn-hover, .card-for-basic-report .btn-hover, .card-large-merchant .btn-hover, .card-large-group .btn-hover, .card-large-report .btn-hover, .card-detail .btn-hover {
    opacity: 0;
    filter: alpha(opacity=0); }
  .card:hover .btn-hover, .card-small:hover .btn-hover, .card-large:hover .btn-hover, .card-map:hover .btn-hover, .card-for-basic-report:hover .btn-hover, .card-large-merchant:hover .btn-hover, .card-large-group:hover .btn-hover, .card-large-report:hover .btn-hover, .card-detail:hover .btn-hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .card .content, .card-small .content, .card-large .content, .card-map .content, .card-for-basic-report .content, .card-large-merchant .content, .card-large-group .content, .card-large-report .content, .card-detail .content {
    padding: 15px; }
  .card .header, .card-small .header, .card-large .header, .card-map .header, .card-for-basic-report .header, .card-large-merchant .header, .card-large-group .header, .card-large-report .header, .card-detail .header {
    height: auto;
    font-size: 2em; }
    @media (max-width: 1600px) {
      .card .header, .card-small .header, .card-large .header, .card-map .header, .card-for-basic-report .header, .card-large-merchant .header, .card-large-group .header, .card-large-report .header, .card-detail .header {
        font-size: 18px; } }
  .card .category, .card-small .category, .card-large .category, .card-map .category, .card-for-basic-report .category, .card-large-merchant .category, .card-large-group .category, .card-large-report .category, .card-detail .category,
  .card label,
  .card-small label,
  .card-large label,
  .card-map label,
  .card-for-basic-report label,
  .card-large-merchant label,
  .card-large-group label,
  .card-large-report label,
  .card-detail label {
    font-size: 14px;
    font-weight: 400;
    color: #9a9a9a;
    margin-bottom: 0px; }
    .card .category i, .card-small .category i, .card-large .category i, .card-map .category i, .card-for-basic-report .category i, .card-large-merchant .category i, .card-large-group .category i, .card-large-report .category i, .card-detail .category i,
    .card label i,
    .card-small label i,
    .card-large label i,
    .card-map label i,
    .card-for-basic-report label i,
    .card-large-merchant label i,
    .card-large-group label i,
    .card-large-report label i,
    .card-detail label i {
      font-size: 16px; }
  
  .card label,
  .card-small label,
  .card-large label,
  .card-map label,
  .card-for-basic-report label,
  .card-large-merchant label,
  .card-large-group label,
  .card-large-report label,
  .card-detail label {
    font-size: 13px;
    margin-bottom: 5px;
    text-transform: uppercase; }
  .card .title, .card-small .title, .card-large .title, .card-map .title, .card-for-basic-report .title, .card-large-merchant .title, .card-large-group .title, .card-large-report .title, .card-detail .title {
    margin: 0;
    color: #333333;
    font-weight: 300;
    text-align: center; }
  .card .avatar, .card-small .avatar, .card-large .avatar, .card-map .avatar, .card-for-basic-report .avatar, .card-large-merchant .avatar, .card-large-group .avatar, .card-large-report .avatar, .card-detail .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px; }
  .card .description, .card-small .description, .card-large .description, .card-map .description, .card-for-basic-report .description, .card-large-merchant .description, .card-large-group .description, .card-large-report .description, .card-detail .description {
    font-size: 14px;
    color: #333; }
  .card .footer, .card-small .footer, .card-large .footer, .card-map .footer, .card-for-basic-report .footer, .card-large-merchant .footer, .card-large-group .footer, .card-large-report .footer, .card-detail .footer {
    padding: 0;
    background-color: transparent;
    line-height: 30px; }
    .card .footer .legend, .card-small .footer .legend, .card-large .footer .legend, .card-map .footer .legend, .card-for-basic-report .footer .legend, .card-large-merchant .footer .legend, .card-large-group .footer .legend, .card-large-report .footer .legend, .card-detail .footer .legend {
      padding: 5px 0; }
    .card .footer hr, .card-small .footer hr, .card-large .footer hr, .card-map .footer hr, .card-for-basic-report .footer hr, .card-large-merchant .footer hr, .card-large-group .footer hr, .card-large-report .footer hr, .card-detail .footer hr {
      margin-top: 5px;
      margin-bottom: 5px; }
  .card .stats, .card-small .stats, .card-large .stats, .card-map .stats, .card-for-basic-report .stats, .card-large-merchant .stats, .card-large-group .stats, .card-large-report .stats, .card-detail .stats {
    color: #a9a9a9; }
  .card .footer div, .card-small .footer div, .card-large .footer div, .card-map .footer div, .card-for-basic-report .footer div, .card-large-merchant .footer div, .card-large-group .footer div, .card-large-report .footer div, .card-detail .footer div {
    display: inline-block; }
  .card .author, .card-small .author, .card-large .author, .card-map .author, .card-for-basic-report .author, .card-large-merchant .author, .card-large-group .author, .card-large-report .author, .card-detail .author {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase; }
  .card .author i, .card-small .author i, .card-large .author i, .card-map .author i, .card-for-basic-report .author i, .card-large-merchant .author i, .card-large-group .author i, .card-large-report .author i, .card-detail .author i {
    font-size: 14px; }
  .card h6, .card-small h6, .card-large h6, .card-map h6, .card-for-basic-report h6, .card-large-merchant h6, .card-large-group h6, .card-large-report h6, .card-detail h6 {
    font-size: 13px;
    margin: 0; }
  .card.card-separator:after, .card-separator.card-small:after, .card-separator.card-large:after, .card-separator.card-map:after, .card-separator.card-for-basic-report:after, .card-separator.card-large-merchant:after, .card-separator.card-large-group:after, .card-separator.card-large-report:after, .card-separator.card-detail:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #dddddd;
    content: "";
    position: absolute; }
  .card .ct-chart, .card-small .ct-chart, .card-large .ct-chart, .card-map .ct-chart, .card-for-basic-report .ct-chart, .card-large-merchant .ct-chart, .card-large-group .ct-chart, .card-large-report .ct-chart, .card-detail .ct-chart {
    margin: 30px 0 30px;
    height: 245px; }
  .card .alert, .card-small .alert, .card-large .alert, .card-map .alert, .card-for-basic-report .alert, .card-large-merchant .alert, .card-large-group .alert, .card-large-report .alert, .card-detail .alert {
    border-radius: 4px;
    position: relative; }
    .card .alert.alert-with-icon, .card-small .alert.alert-with-icon, .card-large .alert.alert-with-icon, .card-map .alert.alert-with-icon, .card-for-basic-report .alert.alert-with-icon, .card-large-merchant .alert.alert-with-icon, .card-large-group .alert.alert-with-icon, .card-large-report .alert.alert-with-icon, .card-detail .alert.alert-with-icon {
      padding-left: 65px; }
  .card .card-body, .card-small .card-body, .card-large .card-body, .card-map .card-body, .card-for-basic-report .card-body, .card-large-merchant .card-body, .card-large-group .card-body, .card-large-report .card-body, .card-detail .card-body {
    font-size: 13px;
    overflow-x: auto; }

.card-user .image {
  height: 110px; }

.card-user .image-plain {
  height: 0;
  margin-top: 110px; }

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px; }

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid #ffffff;
  position: relative;
  margin-bottom: 15px; }
  .card-user .avatar.border-gray {
    border-color: #eeeeee; }

.card-user .title {
  line-height: 24px; }

.card-user .content {
  min-height: 240px; }

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px; }

.card-user hr,
.card-price hr {
  margin: 5px 15px; }

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }
  .card-plain .image {
    border-radius: 4px; }

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px; }
  .card-stats .icon-big i {
    font-weight: bold;
    line-height: 59px; }

.card-stats .numbers {
  font-size: 2em;
  text-align: right; }
  .card-stats .numbers p {
    margin: 0; }

.card-grid {
  margin-top: 15px;
  margin-left: 0px; }

.card-small {
  height: 18%;
  min-height: 100px; }

.card-small-content {
  font-size: 2.5vw;
  margin-top: 5%; }

.card-large, .card-map {
  padding: 15px; }
  @media (min-width: 768px) and (max-width: 992px) {
    .card-large, .card-map {
      height: 40vmax; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .card-large, .card-map {
      height: 35vmax; } }
  @media (min-width: 1200px) {
    .card-large, .card-map {
      width: auto;
      height: 20vmax; } }

.card-for-basic-report {
  padding: 15px; }

@media (min-width: 768px) and (max-width: 992px) {
  .card-large-one {
    height: auto !important; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .card-large-one {
    height: auto !important; } }

.card-large-merchant {
  padding: 15px; }
  @media (min-width: 768px) and (max-width: 992px) {
    .card-large-merchant {
      height: auto; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .card-large-merchant {
      height: 25vmax; } }
  @media (min-width: 1200px) {
    .card-large-merchant {
      width: auto;
      height: 20vmax; } }

.card-large-group {
  padding: 15px; }
  @media (min-width: 768px) and (max-width: 992px) {
    .card-large-group {
      height: auto; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .card-large-group {
      height: 30vmax; } }
  @media (min-width: 1200px) {
    .card-large-group {
      width: auto;
      height: 20vmax; } }

.card-large-report {
  padding: 15px;
  height: auto; }

.card-detail {
  height: 38%;
  min-height: 686px;
  padding: 15px; }
  @media screen and (max-width: 1000px) {
    .card-detail {
      width: auto;
      height: fit-content; } }

.card-large-content {
  font-size: calc(1vw + 2vh);
  text-align: center;
  margin-top: 30%; }
  @media screen and (max-width: 1000px) {
    .card-large-content {
      margin-top: 0%; } }

.card-map {
  height: auto;
  padding: 0;
  max-width: auto; }

.card-container {
  display: flex; }

.card-shop-secret {
  border: 1px solid #d5d5d5;
  min-width: 200px;
  max-width: 400px;
  border-radius: 5px;
  padding: 10px;
  background: #f5f5f5;
  color: gray;
  word-wrap: break-word; }

.param-container {
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 10px;
  background: #f5f5f5;
  color: gray;
  word-wrap: break-word;
  margin: 10px;
  font-size: 12px; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.3rem;
  line-height: 1; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-point {
  stroke-width: 8px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 3px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.8; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #1dc7ea; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-area {
  fill: #1dc7ea; }

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #fb404b; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-area {
  fill: #fb404b; }

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #ffa534; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-area {
  fill: #ffa534; }

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #9368e9; }

.ct-series-d .ct-slice-pie, .ct-series-d .ct-area {
  fill: #9368e9; }

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #87cb16; }

.ct-series-e .ct-slice-pie, .ct-series-e .ct-area {
  fill: #87cb16; }

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #1b8dff; }

.ct-series-f .ct-slice-pie, .ct-series-f .ct-area {
  fill: #1b8dff; }

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #5e5e5e; }

.ct-series-g .ct-slice-pie, .ct-series-g .ct-area {
  fill: #5e5e5e; }

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #dd4b39; }

.ct-series-h .ct-slice-pie, .ct-series-h .ct-area {
  fill: #dd4b39; }

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #35465c; }

.ct-series-i .ct-slice-pie, .ct-series-i .ct-area {
  fill: #35465c; }

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #e52d27; }

.ct-series-j .ct-slice-pie, .ct-series-j .ct-area {
  fill: #e52d27; }

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #55acee; }

.ct-series-k .ct-slice-pie, .ct-series-k .ct-area {
  fill: #55acee; }

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #cc2127; }

.ct-series-l .ct-slice-pie, .ct-series-l .ct-area {
  fill: #cc2127; }

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #1769ff; }

.ct-series-m .ct-slice-pie, .ct-series-m .ct-area {
  fill: #1769ff; }

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-slice-pie, .ct-series-n .ct-area {
  fill: #6188e2; }

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-slice-pie, .ct-series-o .ct-area {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }
  .ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%; }
  .ct-square:after {
    content: "";
    display: table;
    clear: both; }
  .ct-square > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%; }
  .ct-minor-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.88889%; }
  .ct-major-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.33333%; }
  .ct-minor-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%; }
  .ct-major-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%; }
  .ct-perfect-fourth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fourth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.66667%; }
  .ct-perfect-fifth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fifth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%; }
  .ct-minor-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }
  .ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.8047%; }
  .ct-golden-section:after {
    content: "";
    display: table;
    clear: both; }
  .ct-golden-section > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%; }
  .ct-major-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%; }
  .ct-minor-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.33333%; }
  .ct-major-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }
  .ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%; }
  .ct-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%; }
  .ct-major-tenth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-tenth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%; }
  .ct-major-eleventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-eleventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.33333%; }
  .ct-major-twelfth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-twelfth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }
  .ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%; }
  .ct-double-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-double-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

@media (min-width: 992px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px; }
  .footer:not(.footer-big) nav > ul li:first-child {
    margin-left: 0; }
  body > .navbar-collapse.collapse {
    display: none !important; }
  .card form [class*="col-"], .card-small form [class*="col-"], .card-large form [class*="col-"], .card-map form [class*="col-"], .card-for-basic-report form [class*="col-"], .card-large-merchant form [class*="col-"], .card-large-group form [class*="col-"], .card-large-report form [class*="col-"], .card-detail form [class*="col-"] {
    padding: 6px; }
  .card form [class*="col-"]:first-child, .card-small form [class*="col-"]:first-child, .card-large form [class*="col-"]:first-child, .card-map form [class*="col-"]:first-child, .card-for-basic-report form [class*="col-"]:first-child, .card-large-merchant form [class*="col-"]:first-child, .card-large-group form [class*="col-"]:first-child, .card-large-report form [class*="col-"]:first-child, .card-detail form [class*="col-"]:first-child {
    padding-left: 15px; }
  .card form [class*="col-"]:last-child, .card-small form [class*="col-"]:last-child, .card-large form [class*="col-"]:last-child, .card-map form [class*="col-"]:last-child, .card-for-basic-report form [class*="col-"]:last-child, .card-large-merchant form [class*="col-"]:last-child, .card-large-group form [class*="col-"]:last-child, .card-large-report form [class*="col-"]:last-child, .card-detail form [class*="col-"]:last-child {
    padding-right: 15px; } }

/*          Changes for small display      */
@media (max-width: 991px) {
  .main-panel {
    width: 100%; }
  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45); }
  body {
    position: relative; }
  .main-panel {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0; }
  .navbar .container {
    left: 0;
    width: 100%;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative; }
  .navbar-default {
    height: 76px !important; }
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: 0;
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;
    -webkit-transform: translate3d(260px, 0, 0);
    -moz-transform: translate3d(260px, 0, 0);
    -o-transform: translate3d(260px, 0, 0);
    -ms-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    .sidebar > ul {
      position: relative;
      z-index: 4;
      overflow-y: scroll;
      height: calc(100vh - 61px);
      width: 100%; }
  .nav-open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); }
  .nav-open .navbar .container {
    left: -260px; }
  .nav-open .main-panel {
    left: 0;
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform: translate3d(-260px, 0, 0);
    -o-transform: translate3d(-260px, 0, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0); }
  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }
  .navbar-header .navbar-toggle {
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px; }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent; }
  .bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .bar2 {
    opacity: 1; }
  .bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar1 {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar2 {
    opacity: 0; }
  .toggled .bar3 {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg); }
    45% {
      top: 6px;
      transform: rotate(145deg); }
    75% {
      transform: rotate(130deg); }
    100% {
      transform: rotate(135deg); } }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg); }
    75% {
      -webkit-transform: rotate(130deg); }
    100% {
      -webkit-transform: rotate(135deg); } }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg); }
    75% {
      -moz-transform: rotate(130deg); }
    100% {
      -moz-transform: rotate(135deg); } }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg); }
    45% {
      transform: rotate(-10deg); }
    75% {
      transform: rotate(5deg); }
    100% {
      top: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg); }
    45% {
      -webkit-transform: rotate(-10deg); }
    75% {
      -webkit-transform: rotate(5deg); }
    100% {
      top: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg); }
    45% {
      -moz-transform: rotate(-10deg); }
    75% {
      -moz-transform: rotate(5deg); }
    100% {
      top: 0px;
      -moz-transform: rotate(0); } }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg); }
    45% {
      bottom: 6px;
      transform: rotate(-145deg); }
    75% {
      transform: rotate(-130deg); }
    100% {
      transform: rotate(-135deg); } }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg); }
    75% {
      -webkit-transform: rotate(-130deg); }
    100% {
      -webkit-transform: rotate(-135deg); } }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg); }
    75% {
      -moz-transform: rotate(-130deg); }
    100% {
      -moz-transform: rotate(-135deg); } }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg); }
    45% {
      transform: rotate(10deg); }
    75% {
      transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg); }
    45% {
      -webkit-transform: rotate(10deg); }
    75% {
      -webkit-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg); }
    45% {
      -moz-transform: rotate(10deg); }
    75% {
      -moz-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0); } }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .navbar-nav {
    margin: 1px 0; }
  [class*="navbar-"] .navbar-nav > li > a,
  [class*="navbar-"] .navbar-nav > li > a:hover,
  [class*="navbar-"] .navbar-nav > li > a:focus,
  [class*="navbar-"] .navbar-nav .active > a,
  [class*="navbar-"] .navbar-nav .active > a:hover,
  [class*="navbar-"] .navbar-nav .active > a:focus {
    color: white; }
  [class*="navbar-"] .navbar-nav > li > a,
  [class*="navbar-"] .navbar-nav > li > a:hover,
  [class*="navbar-"] .navbar-nav > li > a:focus {
    opacity: 0.7;
    background: transparent; }
  [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu > li > a:active {
    opacity: 1; }
  [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
    border-bottom-color: #777;
    border-top-color: #777; }
  [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
    border-bottom-color: white;
    border-top-color: white; }
  .navbar-fixed-top {
    -webkit-backface-visibility: hidden; }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 250px;
    content: "";
    z-index: 9999;
    overflow-x: hidden; }
  .social-line .btn {
    margin: 0 0 10px 0; }
  .subscribe-line .form-control {
    margin: 0 0 10px 0; }
  .social-line.pull-right {
    float: none; }
  .footer nav.pull-left {
    float: none !important; }
  .footer:not(.footer-big) nav > ul li {
    float: none; }
  .social-area.pull-right {
    float: none !important; }
  .form-control + .form-control-feedback {
    margin-top: -8px; }
  .navbar-toggle:hover,
  .navbar-toggle:focus {
    background-color: transparent !important; }
  .btn.dropdown-toggle {
    margin-bottom: 0; }
  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px; }
  .media-post .media-body {
    width: 100%; }
  .navbar-header .collapse,
  .navbar-toggle {
    display: block !important; } }

@media (min-width: 992px) {
  .table-full-width {
    margin-left: -15px;
    margin-right: -15px; }
  .table-responsive {
    width: 100%;
    overflow: visible; } }

@media (max-width: 991px) {
  .table-responsive {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; } }

@media print {
  #sidebar,
  .btn,
  #sidebar-hide-button-div {
    display: none;
    width: 0 px; }
  #main-panel {
    width: 100% !important; } }

.panel-heading {
  color: #333;
  background-color: white !important;
  border-color: #ddd; }

.status-icon-success {
  position: relative;
  color: rgba(0, 128, 0, 0.3);
  font-size: 18px;
  margin: 5px;
  cursor: pointer;
  transition: all .2s; }

.status-icon-failed {
  position: relative;
  color: rgba(255, 0, 0, 0.3);
  font-size: 18px;
  cursor: pointer;
  transition: all .3s; }

.status-icon-success:hover {
  color: #5BB795; }

.status-icon-failed:hover {
  color: #EC5E6F; }

.step-icons {
  position: relative;
  height: 60px;
  width: 150px; }

.span-container {
  cursor: pointer;
  margin-right: -6px; }

.step-icon-success, .step-icon-failed, .step-icon-pending, .step-icon-waiting {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: 4px;
  height: 25px;
  width: 25px;
  font-size: 1em;
  cursor: pointer;
  padding-left: 2px;
  border: 1px solid green;
  border-radius: 12px;
  text-align: left;
  align-items: center !important;
  transition: all .3s; }

.step-icon-success:hover, .step-icon-failed:hover, .step-icon-pending:hover, .step-icon-waiting:hover {
  background-color: rgba(155, 218, 162, 0.3);
  cursor: pointer;
  width: 40px;
  transition: all .3s; }

.step-icon-success:hover + .arrow-icon, .step-icon-failed:hover + .arrow-icon, .step-icon-pending:hover + .arrow-icon, .step-icon-waiting:hover + .arrow-icon, .step-icon-success:hover + .arrow-icon-blue, .step-icon-failed:hover + .arrow-icon-blue, .step-icon-pending:hover + .arrow-icon-blue, .step-icon-waiting:hover + .arrow-icon-blue, .step-icon-success:hover + .arrow-icon-red, .step-icon-failed:hover + .arrow-icon-red, .step-icon-pending:hover + .arrow-icon-red, .step-icon-waiting:hover + .arrow-icon-red, .step-icon-success:hover + .arrow-icon-green, .step-icon-failed:hover + .arrow-icon-green, .step-icon-pending:hover + .arrow-icon-green, .step-icon-waiting:hover + .arrow-icon-green, .step-icon-success:hover + .arrow-icon-grey, .step-icon-failed:hover + .arrow-icon-grey, .step-icon-pending:hover + .arrow-icon-grey, .step-icon-waiting:hover + .arrow-icon-grey {
  opacity: 1; }

.step-icon-failed {
  padding-left: 2.5px;
  border-color: red; }

.step-icon-failed:hover {
  background-color: rgba(218, 155, 155, 0.3);
  align-content: right !important; }

.step-icon-pending {
  border-color: #4949fd; }

.step-icon-pending:hover {
  background-color: rgba(155, 203, 218, 0.3); }

.step-icon-waiting {
  border-color: #cfcfcf; }

.step-icon-waiting:hover {
  background-color: rgba(201, 201, 201, 0.3); }

.arrow-icon, .arrow-icon-blue, .arrow-icon-red, .arrow-icon-green, .arrow-icon-grey {
  position: relative;
  opacity: 0;
  right: 20px;
  top: 7px;
  transition: all 0.1s; }

.arrow-icon-blue {
  color: blue; }

.arrow-icon-red {
  color: red; }

.arrow-icon-green {
  color: green; }

.arrow-icon-grey {
  color: #cacaca; }

.row {
  margin: 0px !important; }

[class^="col"] {
  margin: 0px !important;
  padding: 0px !important; }
